import { handleResponse } from '@edp/core-common-frontend'
import { GeneralSettingsSpec } from '@edp/types'
import { ApiClient } from 'api/ApiClient'
import { getAuthType, getJWT } from 'api/utils'

export async function getGeneralSettings(this: ApiClient): Promise<any> {
  const url = `${this.serverUrl}/settings`

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })
  return await handleResponse(response)
}

export async function updateGeneralSettings(
  this: ApiClient,
  spec: GeneralSettingsSpec
): Promise<any> {
  const url = `${this.serverUrl}/settings`

  const body = JSON.stringify(spec)

  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
    body: body,
  })
  return await handleResponse(response)
}

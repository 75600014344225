import {
  GeneralSettingsSpec,
  PrometheusConfigMetricBaseSpec,
  TeamPrometheusConfigMetricSpec,
  TeamSpec,
} from '@edp/types'
import { EnvironmentSpec } from 'types/Managers/Environments'
import { CircularProgress, Divider, Grid, Typography } from '@mui/material'
import { ApiClient } from 'api/ApiClient'
import { useEffect, useState } from 'react'
import UserInteractionPrometheusMetricInput from 'components/UserInteraction/UserInteractionPrometheusMetricInput'
import { UserInteractionAddButton } from 'components/UserInteraction/UserInteractionAddButton'
import _ from 'lodash'

export interface PrometheusProps {
  generalSettings?: GeneralSettingsSpec
  loadingGeneralSettings: boolean
  team: TeamSpec
  setTeam: React.Dispatch<React.SetStateAction<TeamSpec>>
}

interface TeamPrometheusMetricItemProps {
  generalPrometheusConfigurations?: PrometheusConfigMetricBaseSpec[]
  environments: EnvironmentSpec[]
  metric: PrometheusConfigMetricBaseSpec
  index: number
  team: TeamSpec
  setTeam: React.Dispatch<React.SetStateAction<TeamSpec>>
}

const TeamPrometheusMetricItem = (props: TeamPrometheusMetricItemProps) => {
  const [error, setError] = useState<boolean>(false)

  const handleChangeEnvironment = (index: number, value: string) => {
    const team = { ...props.team }

    if (team.prometheusConfigRef !== undefined) {
      team.prometheusConfigRef.metrics[index].environment = value
      props.setTeam(team)
      existGeneralMetric(team.prometheusConfigRef?.metrics[index])
    }
  }

  const handleChangeName = (index: number, value: string) => {
    const team = { ...props.team }

    if (team.prometheusConfigRef !== undefined) {
      team.prometheusConfigRef.metrics[index].name = value
      props.setTeam(team)
      existGeneralMetric(team.prometheusConfigRef?.metrics[index])
    }
  }

  const handleChangeMetric = (index: number, value: string) => {
    const team = { ...props.team }

    if (team.prometheusConfigRef !== undefined) {
      team.prometheusConfigRef.metrics[index].metric = value
      props.setTeam(team)
      existGeneralMetric(team.prometheusConfigRef?.metrics[index])
    }
  }

  const handleChangeThreshold = (index: number, value: string) => {
    const validate = (value: string) => {
      if (value === '') {
        return true
      }

      const pattern = /^[-+]?(\d+(\.\d*)?|\.\d+)([eE][-+]?\d+)?$/
      return pattern.test(value)
    }

    if (!validate(value)) {
      return
    }

    const team = { ...props.team }

    if (team.prometheusConfigRef !== undefined) {
      team.prometheusConfigRef.metrics[index].threshold = value
      props.setTeam(team)
      existGeneralMetric(team.prometheusConfigRef?.metrics[index])
    }
  }

  const handleChangeUnit = (index: number, value: string) => {
    const team = { ...props.team }

    if (team.prometheusConfigRef !== undefined) {
      team.prometheusConfigRef.metrics[index].unit = value
      props.setTeam(team)
      existGeneralMetric(team.prometheusConfigRef?.metrics[index])
    }
  }

  const handleRemoveMetric = (index: number) => {
    const team = { ...props.team }

    if (team.prometheusConfigRef !== undefined) {
      team.prometheusConfigRef.metrics.splice(index, 1)
      props.setTeam(team)
    }
  }

  const existGeneralMetric = (metric: TeamPrometheusConfigMetricSpec) => {
    if (
      props.generalPrometheusConfigurations &&
      props.generalPrometheusConfigurations.length !== 0
    ) {
      const index = _.findIndex(
        props.generalPrometheusConfigurations,
        (obj) => {
          return (
            obj.environment === metric.environment &&
            obj.name === metric.name &&
            obj.metric === metric.metric &&
            obj.threshold === metric.threshold &&
            obj.unit === metric.unit
          )
        }
      )

      if (index === -1) {
        setError(false)
      } else {
        setError(true)
      }
    }
  }

  return (
    <UserInteractionPrometheusMetricInput
      key={`prometheus-config-metric-${props.index}`}
      helperText={`Заполните параметры метрики. Запрос составляется в формате PromQL, результатом должно быть число.`}
      index={props.index}
      environments={props.environments}
      selectedEnvironment={props.metric.environment}
      second={props.metric.name}
      third={props.metric.metric}
      fourth={props.metric.threshold}
      fift={props.metric.unit}
      secondLabel="Название"
      thirdLabel="Метрика"
      fourthLabel="Порог"
      fiftLabel="Единица"
      error={error}
      handleChangeEnvironment={(e) => {
        handleChangeEnvironment(props.index, e.toString())
      }}
      handleChangeSecond={(e) => {
        handleChangeName(props.index, e.target.value)
      }}
      handleChangeThird={(e) => {
        handleChangeMetric(props.index, e.target.value)
      }}
      handleChangeFourth={(e) => {
        handleChangeThreshold(props.index, e.target.value)
      }}
      handleChangeFift={(e) => {
        handleChangeUnit(props.index, e.target.value)
      }}
      removeButton
      handleRemove={() => {
        handleRemoveMetric(props.index)
      }}
    />
  )
}

export const Prometheus = (props: PrometheusProps) => {
  const [environments, setEnvironments] = useState<EnvironmentSpec[]>()
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    const apiClient = new ApiClient()

    const getEnvironments = async () => {
      try {
        setLoading(true)
        const data = await apiClient.getEnvironments()
        setEnvironments(data)
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    }

    getEnvironments()
  }, [])

  const handleAddMetric = () => {
    const team = { ...props.team }
    if (team.prometheusConfigRef?.metrics !== undefined) {
      if (team.prometheusConfigRef.metrics.length !== 0) {
        if (
          team.prometheusConfigRef.metrics[
            team.prometheusConfigRef.metrics.length - 1
          ].name !== '' ||
          team.prometheusConfigRef.metrics[
            team.prometheusConfigRef.metrics.length - 1
          ].metric !== ''
        ) {
          team.prometheusConfigRef.metrics.push({
            team: '',
            environment: '',
            name: '',
            metric: '',
            threshold: '',
            unit: '',
          })
        }
      } else {
        team.prometheusConfigRef.metrics.push({
          team: '',
          environment: '',
          name: '',
          metric: '',
          threshold: '',
          unit: '',
        })
      }
    } else {
      team.prometheusConfigRef = {
        team: team.uuid,
        metrics: [
          {
            team: '',
            environment: '',
            name: '',
            metric: '',
            threshold: '',
            unit: '',
          },
        ],
      }
    }

    props.setTeam(team)
  }

  return (
    <>
      <Typography
        variant="body1"
        paddingTop="30px"
        className="Typography-Body1"
      >
        Prometheus
      </Typography>
      {!loading && !props.loadingGeneralSettings ? (
        <>
          {props.generalSettings &&
            props.generalSettings.prometheusMetrics.length > 0 && (
              <Grid
                sx={{
                  marginBottom: '10px',
                }}
              >
                <Grid
                  sx={{
                    width: '800px',
                  }}
                >
                  <Divider>Общие метрики</Divider>
                </Grid>
                {props.generalSettings.prometheusMetrics.map(
                  (metric: PrometheusConfigMetricBaseSpec, index: number) => {
                    return (
                      <UserInteractionPrometheusMetricInput
                        key={`general-prometheus-config-metric-${index}`}
                        helperText={`Заполните параметры метрики. Запрос составляется в формате PromQL, результатом должно быть число.`}
                        index={index}
                        environments={environments}
                        selectedEnvironment={metric.environment}
                        second={metric.name}
                        third={metric.metric}
                        fourth={metric.threshold}
                        fift={metric.unit}
                        secondLabel="Название"
                        thirdLabel="Метрика"
                        fourthLabel="Порог"
                        fiftLabel="Единица"
                        environmentDisabled={true}
                        secondReadOnly={true}
                        secondDisabled={true}
                        thirdReadOnly={true}
                        thirdDisabled={true}
                        fourthReadOnly={true}
                        fourthDisabled={true}
                        fiftReadOnly={true}
                        fiftDisabled={true}
                      />
                    )
                  }
                )}
              </Grid>
            )}
          <Grid
            sx={{
              width: '800px',
            }}
          >
            <Divider>Метрики команды</Divider>
          </Grid>
          {props.team.prometheusConfigRef?.metrics && (
            <>
              {props.team.prometheusConfigRef?.metrics.length > 0 &&
                props.team.prometheusConfigRef?.metrics.map(
                  (metric: TeamPrometheusConfigMetricSpec, index: number) => {
                    return (
                      <TeamPrometheusMetricItem
                        generalPrometheusConfigurations={
                          props.generalSettings?.prometheusMetrics
                        }
                        environments={environments || []}
                        metric={metric}
                        index={index}
                        team={props.team}
                        setTeam={props.setTeam}
                      />
                    )
                  }
                )}
            </>
          )}
          <UserInteractionAddButton handleAdd={handleAddMetric} />
        </>
      ) : (
        <>
          <CircularProgress size={20} />
        </>
      )}
    </>
  )
}

export default Prometheus

import { useTheme } from '@edp/core-common-frontend/dist/packages/mui/material'
import { Circle } from 'rc-progress'

export const PercentageNegative = ({ value }: { value?: string }) => {
  const theme = useTheme()

  return (
    <Circle
      style={{
        height: '30px',
        width: '30px',
      }}
      strokeLinecap="butt"
      percent={+(value || 0)}
      strokeWidth={16}
      strokeColor={theme.palette.error.light}
      trailColor={theme.palette.success.light}
      trailWidth={16}
    />
  )
}

export const PercentagePositive = ({ value }: { value?: string }) => {
  const theme = useTheme()

  return (
    <Circle
      style={{
        height: '30px',
        width: '30px',
      }}
      strokeLinecap="butt"
      percent={+(value || 0)}
      strokeWidth={16}
      strokeColor={theme.palette.success.light}
      trailColor={theme.palette.error.light}
      trailWidth={16}
    />
  )
}

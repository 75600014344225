import { NothingFoundPage } from '@edp/core-common-frontend'
import { ServiceSonarqubeFindingsResultSpec } from '@edp/types'
import { Grid, LinearProgress } from '@mui/material'
import { ApiClient } from 'api/ApiClient'
import { SonarqubeCard } from 'components/SonarqubeCard'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

export interface SonarQubePanelProps {}

export const SonarQubePanel = (props: SonarQubePanelProps) => {
  const { uuid } = useParams()

  const [serviceSonarqubeFindings, setServiceSonarqubeFindings] = useState<
    ServiceSonarqubeFindingsResultSpec[]
  >([])
  const [loading, setLoading] = useState<boolean>(false)

  const apiClient = new ApiClient()

  useEffect(() => {
    const getFindings = async () => {
      try {
        if (uuid) {
          setLoading(true)
          const data = await apiClient.getServiceSonarqubeFindingsSummary(uuid)
          setServiceSonarqubeFindings(data)
        }
      } catch (e) {
        console.error(e)
      } finally {
        setLoading(false)
      }
    }
    getFindings()
  }, [])

  return (
    <>
      {!loading ? (
        <>
          {serviceSonarqubeFindings.length !== 0 ? (
            <Grid container spacing={2}>
              {Array.from(serviceSonarqubeFindings.entries()).map(
                ([key, value]) => {
                  return (
                    <Grid item sm={12}>
                      <SonarqubeCard spec={value} />
                    </Grid>
                  )
                }
              )}
            </Grid>
          ) : (
            <>
              <NothingFoundPage
                text={
                  'Попробуйте добавить проект SonarQube в настройках команды или сервиса'
                }
              />
            </>
          )}
        </>
      ) : (
        <>
          <LinearProgress />
        </>
      )}
    </>
  )
}

export default SonarQubePanel

import * as actions from './methods/Actions'
import * as artifacts from './methods/Artifacts'
import * as clusters from './methods/Clusters'
import * as credentials from './methods/Credentials'
import * as customEntities from './methods/CustomEntities'
import * as environments from './methods/Environments'
import * as generalSettings from './methods/GeneralSettings'
import * as gitlab from './methods/Gitlab'
import * as harbor from './methods/HelmCharts'
import * as helmCharts from './methods/HelmCharts'
import * as infraService from './methods/InfraService'
import * as instances from './methods/Instances'
import * as kafka from './methods/Kafka'
import * as kubernetesNamespaces from './methods/KubernetesNamespaces'
import * as kyverno from './methods/Kyverno'
import * as auth from './methods/LocalAccounts'
import * as opensearch from './methods/Opensearch'
import * as operations from './methods/Operations'
import * as organization from './methods/Organization'
import * as platformServiceConfigurations from './methods/PlatformServiceConfigurations'
import * as platformServiceInstances from './methods/PlatformServiceInstances'
import * as platformServiceTemplates from './methods/PlatformServiceTemplates'
import * as repositories from './methods/Repositories'
import * as security from './methods/Security'
import * as serviceTemplates from './methods/ServiceTemplates'
import * as services from './methods/Services'
import * as signature from './methods/Signature'
import * as teams from './methods/Teams'
import * as techRadar from './methods/TechRadar'
import * as terraformAction from './methods/TerraformActions'
import * as lifecycle from './methods/Lifecycle'
import * as permissions from './methods/rbac/permissions'
import * as roleBindings from './methods/rbac/roleBindings'
import * as roles from './methods/rbac/roles'

const SERVER_URL = window.env.REACT_APP_SERVER_URL

export class ApiClient {
  protected serverUrl: string

  public constructor() {
    this.serverUrl = SERVER_URL
  }

  public createInstance = instances.createInstance
  public getInstances = instances.getInstances
  public getInstancesBy = instances.getInstancesBy
  public getInstance = instances.getInstance
  public updateInstance = instances.updateInstance
  public getInstanceResources = instances.getInstanceResources
  public getInstanceGrafanaPanels = instances.getInstanceGrafanaPanels
  public getInstanceSecretsUrl = instances.getInstanceSecretsUrl
  public deleteInstance = instances.deleteInstance

  public createEnvironment = environments.createEnvironment
  public getEnvironments = environments.getEnvironments
  public getEnvironmentsBy = environments.getEnvironmentsBy
  public getEnvironment = environments.getEnvironment
  public updateEnvironment = environments.updateEnvironment
  public getEnvironmentInstances = environments.getEnvironmentInstances
  public deleteEnvironment = environments.deleteEnvironment

  public getGeneralSettings = generalSettings.getGeneralSettings
  public updateGeneralSettings = generalSettings.updateGeneralSettings

  public createTeam = teams.createTeam
  public getTeams = teams.getTeams
  public getTeam = teams.getTeam
  public updateTeam = teams.updateTeam
  public getKeycloakGroupMembers = teams.getKeycloakGroupMembers
  public deleteTeam = teams.deleteTeam
  public syncGitlabGroupMembers = teams.syncGitlabGroupMembers
  public getGitlabGroupMembers = teams.getGitlabGroupMembers
  public getTeamPrometheusMetricsConfigurations =
    teams.getTeamPrometheusMetricsConfigurations
  public getTeamSonarqubeConfigurations = teams.getTeamSonarqubeConfigurations
  public getTeamAvailableCustomVariables = teams.getTeamAvailableCustomVariables
  public getTeamPresettings = teams.getTeamPresettings

  public createPlatformServiceTemplate =
    platformServiceTemplates.createPlatformServiceTemplate
  public getPlatformServiceTemplates =
    platformServiceTemplates.getPlatformServiceTemplates
  public getPlatformServiceTemplate =
    platformServiceTemplates.getPlatformServiceTemplate
  public updatePlatformServiceTemplate =
    platformServiceTemplates.updatePlatformServiceTemplate
  public deletePlatformServiceTemplate =
    platformServiceTemplates.deletePlatformServiceTemplate

  public createPlatformServiceInstance =
    platformServiceInstances.createPlatformServiceInstance
  public getPlatformServiceInstances =
    platformServiceInstances.getPlatformServiceInstances
  public getPlatformServiceInstance =
    platformServiceInstances.getPlatformServiceInstance
  public updatePlatformServiceInstance =
    platformServiceInstances.updatePlatformServiceInstance
  public deletePlatformServiceInstance =
    platformServiceInstances.deletePlatformServiceInstance

  public createPlatformServiceConfiguration =
    platformServiceConfigurations.createPlatformServiceConfiguration
  public getPlatformServiceConfigurations =
    platformServiceConfigurations.getPlatformServiceConfigurations
  public getPlatformServiceConfigurationsBy =
    platformServiceConfigurations.getPlatformServiceConfigurationsBy
  public getPlatformServiceConfiguration =
    platformServiceConfigurations.getPlatformServiceConfiguration
  public updatePlatformServiceConfiguration =
    platformServiceConfigurations.updatePlatformServiceConfiguration
  public deletePlatformServiceConfiguration =
    platformServiceConfigurations.deletePlatformServiceConfiguration

  public getServiceTags = services.getServiceTags
  public getServiceRepositoryTags = services.getServiceRepositoryTags
  public createServiceRepositoryTag = services.createServiceRepositoryTag
  public getServiceInstancesInfo = services.getServiceInstancesInfo
  public getServiceBranches = services.getServiceBranches
  public getArtifactDockerRegistryUrl = services.getArtifactDockerRegistryUrl
  public startPipeline = services.startPipeline
  public getServicePipelinesSummary = services.getPipelinesSummary
  public getMergeRequestsSummary = services.getMergeRequestsSummary
  public getLanguagesSummary = services.getLanguagesSummary
  public getIssuesSummary = services.getIssuesSummary
  public getContributorsSummary = services.getContributorsSummary
  public getServiceGrafanaPanels = services.getServiceGrafanaPanels
  public getServiceSecrets = services.getServiceSecrets
  public getServiceSbom = services.getServiceSbom
  public getServiceApiSpecificationByLink =
    services.getServiceApiSpecificationByLink
  public getServiceApiSpecificationByBranch =
    services.getServiceApiSpecificationByBranch
  public getServiceReadme = services.getServiceReadme
  public getServiceInfo = services.getServiceInfo
  public getServiceNamespaces = services.getServiceNamespaces
  public getServiceHelmReleases = services.getServiceHelmReleases
  public getServiceHelmReleasesNamespaces =
    services.getServiceHelmReleasesNamespaces
  public getServiceUpdateLock = services.getServiceUpdateLock
  public getServicesByLifecycleStage = services.getServicesByLifecycleStage
  public serviceConfigurationValidate = services.serviceConfigurationValidate
  public serviceUpdateTemplate = services.serviceUpdateTemplate
  public serviceUpdateTemplateExist = services.serviceUpdateTemplateExist
  public serviceUpdateTemplateBranchExist =
    services.serviceUpdateTemplateBranchExist
  public deleteSbom = services.deleteSbom
  public deleteServiceUpdateLock = services.deleteServiceUpdateLock
  public getServicePrometheusMetrics = services.getServicePrometheusMetrics
  public getServicePrometheusMetricsHealthcheck =
    services.getServicePrometheusMetricsHealthcheck
  public getServiceSonarqubeFindingsSummary =
    services.getServiceSonarqubeFindingsSummary

  public createRepository = repositories.createRepository
  public getRepositories = repositories.getRepositories
  public getRepository = repositories.getRepository
  public updateRepository = repositories.updateRepository
  public runRepositorySync = repositories.runRepositorySync
  public deleteRepository = repositories.deleteRepository
  public getRepositoryVariables = repositories.getRepositoryVariables
  public getRepositoryVariablesByVersion =
    repositories.getRepositoryVariablesByVersion
  public getRepositoryProjectLevelVariables =
    repositories.getRepositoryProjectLevelVariables
  public getRepositoryProjectLevelVariablesByVersion =
    repositories.getRepositoryProjectLevelVariablesByVersion
  public getRepositoryVariablesBranches =
    repositories.getRepositoryVariablesBranches
  public getRepositoryVariablesBranchesByVersion =
    repositories.getRepositoryVariablesBranchesByVersion
  public getRepositoryBranches = repositories.getRepositoryBranches
  public getRepositoryLastTag = repositories.getRepositoryLastTag

  public createCredentials = credentials.createCredentials
  public getCredentialsArray = credentials.getCredentialsArray
  public getCredentials = credentials.getCredentials
  public deleteCredentials = credentials.deleteCredentials

  public createCluster = clusters.createCluster
  public getClusters = clusters.getClusters
  public getCluster = clusters.getCluster
  public updateCluster = clusters.updateCluster
  public getClusterInfo = clusters.getClusterInfo
  public getClusterResources = clusters.getClusterResources
  public getClusterObjects = clusters.getClusterObjects
  public deleteCluster = clusters.deleteCluster
  public checkClusterHealth = clusters.checkClusterHealth

  public createHelmChart = helmCharts.createHelmChart
  public getHelmCharts = helmCharts.getHelmCharts
  public getHelmChart = helmCharts.getHelmChart
  public updateHelmChart = helmCharts.updateHelmChart

  public getHelmChartDetails = helmCharts.getHelmChartDetails
  public getHelmChartVersions = helmCharts.getHelmChartVersions
  public getHelmChartRegistryUrl = helmCharts.getHelmChartRegistryUrl
  public getHelmChartsFromRepository = helmCharts.getHelmChartsFromRepository
  public getHarborHelmCharts = helmCharts.getHarborHelmCharts
  public deleteHelmChart = helmCharts.deleteHelmChart
  public getHelmChartsFromRegistry = helmCharts.getHelmChartsFromRegistry

  public createInfraService = infraService.createInfraService
  public getInfraService = infraService.getInfraService
  public getInfraServicesByType = infraService.getInfraServicesByType
  public getInfraServices = infraService.getInfraServices
  public getInfraServiceInfo = infraService.getInfraServiceInfo
  public getInfraServiceUrl = infraService.getInfraServiceUrl
  public deleteInfraService = infraService.deleteInfraService
  public checkConnectInfraService = infraService.checkConnectInfraService

  public getOperations = operations.getOperations
  public getOperation = operations.getOperation
  public retryOperation = operations.retryOperation

  public login = auth.login
  public changePassword = auth.changePassword

  public createSignatureImage = signature.createSignatureImage
  public getSignaturePublicKey = signature.getSignaturePublicKey
  public updateSignaturePrivateKey = signature.updateSignaturePrivateKey
  public deleteSignatureImage = signature.deleteSignatureImage

  public startScanGitSecrets = security.startScanGitSecrets
  public getSecretsRepository = security.getSecretsRepository
  public getStatusScanGitSecrets = security.getStatusScanGitSecrets
  public updateStatusGitSecret = security.updateStatusGitSecret

  public createKafkaCluster = kafka.createKafkaCluster
  public getKafkaClusters = kafka.getKafkaClusters
  public getKafkaClustersBy = kafka.getKafkaClustersBy
  public getKafkaCluster = kafka.getKafkaCluster
  public updateKafkaCluster = kafka.updateKafkaCluster
  public deleteKafkaCluster = kafka.deleteKafkaCluster

  public createKafkaTopic = kafka.createKafkaTopic
  public getKafkaTopics = kafka.getKafkaTopics
  public getKafkaTopic = kafka.getKafkaTopic
  public updateKafkaTopic = kafka.updateKafkaTopic
  public deleteKafkaTopic = kafka.deleteKafkaTopic

  public createKafkaAcl = kafka.createKafkaAcl
  public getKafkaAcls = kafka.getKafkaAcls
  public deleteKafkaAcl = kafka.deleteKafkaAcl

  public getKubernetesNamespaceHelmReleases =
    kubernetesNamespaces.getKubernetesNamespaceHelmReleases

  public getOpensearchUrl = opensearch.getOpensearchUrl

  public getKyvernoClusterPolicies = kyverno.getKyvernoClusterPolicies
  public getKyvernoClusterPolicy = kyverno.getKyvernoClusterPolicy

  public createPermissions = permissions.createPermissions
  public getPermissions = permissions.getPermissions
  public deletePermission = permissions.deletePermission

  public createRole = roles.createRole
  public getRoles = roles.getRoles
  public getRole = roles.getRole
  public updateRole = roles.updateRole
  public deleteRole = roles.deleteRole

  public createRoleBinding = roleBindings.createRoleBinding
  public getRoleBindings = roleBindings.getRoleBindings
  public getRoleBinding = roleBindings.getRoleBinding
  public updateRoleBinding = roleBindings.updateRoleBinding
  public deleteRoleBinding = roleBindings.deleteRoleBinding

  public getArtifactLabels = artifacts.getArtifactLabels

  public createCustomEntity = customEntities.createCustomEntity
  public getCustomEntities = customEntities.getCustomEntities
  public getCustomEntitiesBy = customEntities.getCustomEntitiesBy
  public getCustomEntity = customEntities.getCustomEntity
  public updateCustomEntity = customEntities.updateCustomEntity
  public deleteCustomEntity = customEntities.deleteCustomEntity

  public getActions = actions.getActions
  public deleteAction = actions.deleteAction
  public createAction = actions.createAction
  public getAction = actions.getAction
  public updateAction = actions.updateAction
  public getActionPipelinesSummary = actions.getPipelinesSummary

  public getTechRadarData = techRadar.getTechRadarData
  public getTechRadarUrl = techRadar.getTechRadarUrl

  public createServiceTemplate = serviceTemplates.createServiceTemplate
  public getServiceTemplates = serviceTemplates.getServiceTemplates
  public getServiceTemplatesBy = serviceTemplates.getServiceTemplatesBy
  public getServiceTemplate = serviceTemplates.getServiceTemplate
  public serviceTemplateConfigurationValidate =
    serviceTemplates.serviceTemplateConfigurationValidate
  public serviceTemplateConfigurationValidateByVersion =
    serviceTemplates.serviceTemplateConfigurationValidateByVersion
  public getServiceTemplateVersions =
    serviceTemplates.getServiceTemplateVersions
  public getServiceTemplateLastVersion =
    serviceTemplates.getServiceTemplateLastVersion
  public updateServiceTemplate = serviceTemplates.updateServiceTemplate
  public updateServiceTemplateLastVersion =
    serviceTemplates.updateServiceTemplateLastVersion
  public deleteServiceTemplate = serviceTemplates.deleteServiceTemplate

  public getGitlabUrl = gitlab.getGitlabUrl
  public getGitlabGroups = gitlab.getGitlabGroups
  public getGitlabBranchDefault = gitlab.getGitlabBranchDefault

  public getHarborUrl = harbor.getHarborUrl

  public getOrganizationDashboard = organization.getOrganizationDashboard


  public createTerraformAction = terraformAction.createTerraformAction
  public getTerraformActions = terraformAction.getTerraformActions
  public getTerraformActionsBy = terraformAction.getTerraformActionsBy
  public getTerraformAction = terraformAction.getTerraformAction
  public getTerraformActionVariables =
    terraformAction.getTerraformActionVariables
  public getTerraformActionRecord = terraformAction.getTerraformActionRecord
  public getTerraformActionRecords = terraformAction.getTerraformActionRecords
  public updateTerraformAction = terraformAction.updateTerraformAction
  public deleteTerraformAction = terraformAction.deleteTerraformAction
  public terraformActionInit = terraformAction.terraformActionInit
  public terraformActionPlan = terraformAction.terraformActionPlan
  public terraformActionApply = terraformAction.terraformActionApply
  public terraformActionRun = terraformAction.terraformActionRun
  public getTerraformActionRecordLogs =
    terraformAction.getTerraformActionRecordLogs

  public createLifecycleStage = lifecycle.createLifecycleStage
  public getLifecycleStages = lifecycle.getLifecycleStages
  public getLifecycleStage = lifecycle.getLifecycleStage
  public updateLifecycleStage = lifecycle.updateLifecycleStage
  public deleteLifecycleStage = lifecycle.deleteLifecycleStage
}

import {
  NothingFoundPage,
  getServices,
  getTeams,
} from '@edp/core-common-frontend'
import { ServiceSpec, TeamSpec } from '@edp/types'
import { Box, Grid, LinearProgress } from '@mui/material'
import { ComponentCard } from 'components/ComponentCard'
import FillingStatusComponent, {
  FillingStatusComponentType,
} from 'pages/Services/FillingStatus/FillingStatusComponent'
import HealthcheckStatusComponent from 'pages/Services/HealtcheckStatus/HealthcheckStatus'
import { useEffect, useState } from 'react'

interface ServicesPanelProps {
  team?: TeamSpec
}

export const ServicesPanel = (props: ServicesPanelProps) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [allServices, setAllServices] = useState<ServiceSpec[]>([])
  const [teamServices, setTeamServices] = useState<ServiceSpec[]>([])
  const [teams, setTeams] = useState<TeamSpec[]>([])
  const [allIncludesTeams, setAllIncludesTeams] = useState<TeamSpec[]>([])

  const getChildTeams = () => {
    if (!props.team) {
      return
    }

    const tmpAllIncludesTeams: TeamSpec[] = []

    for (const team of teams) {
      if (team.uuid === props.team.uuid) {
        tmpAllIncludesTeams.push(team)
        break
      }
    }

    for (;;) {
      const oldLength = tmpAllIncludesTeams.length
      for (const team of teams) {
        if (team.parentTeam) {
          const existParent = tmpAllIncludesTeams.some(
            (value) => value.uuid === team.parentTeam
          )
          const existTeam = tmpAllIncludesTeams.some(
            (value) => value.uuid === team.uuid
          )

          if (existParent && !existTeam) {
            tmpAllIncludesTeams.push(team)
          }
        }
      }

      if (tmpAllIncludesTeams.length === oldLength) {
        break
      }
    }
    setAllIncludesTeams(tmpAllIncludesTeams)
  }

  const fetchTeams = async () => {
    try {
      setLoading(true)

      const teams = await getTeams()
      setTeams(teams)
    } catch (e) {
      console.error('Ошибка при получении данных:', e)
    } finally {
      setLoading(false)
    }
  }

  const fetchServices = async () => {
    try {
      setLoading(true)

      if (props.team) {
        const services = await getServices()
        setAllServices(services)
      }
    } catch (e) {
      console.error('Ошибка при получении данных:', e)
    } finally {
      setLoading(false)
    }
  }

  const filteredServices = async () => {
    let filteredServices: ServiceSpec[] = []

    for (const service of allServices) {
      if (allIncludesTeams.some((value) => value.uuid === service.owner)) {
        filteredServices = [...filteredServices, service]
      }
      setTeamServices(filteredServices)
    }
  }

  useEffect(() => {
    fetchServices()
    fetchTeams()
  }, [])

  useEffect(() => {
    getChildTeams()
  }, [props.team, teams])

  useEffect(() => {
    filteredServices()
  }, [props.team, allIncludesTeams, allServices])

  return (
    <>
      {loading && <LinearProgress />}
      {!loading && teamServices.length == 0 && (
        <NothingFoundPage
          text={`Попробуйте создать новый сервис и указать владельцем команду ${props.team?.name}.`}
        />
      )}
      {!loading && teamServices.length > 0 && (
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(22em, 1fr))',
            gridAutoRows: '1fr',
            gridGap: 10,
          }}
        >
          {teamServices?.map((service, index) => (
            <ComponentCard
              key={index}
              name={service.name}
              uuid={service.uuid}
              description={service.description}
              headerAction={
                <>
                  <Grid
                    container
                    sx={{
                      height: '32px',
                    }}
                  >
                    <Grid
                      item
                      sm={2}
                      sx={{
                        height: '32px',
                      }}
                    />
                    <Grid
                      item
                      sm={4}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '32px',
                      }}
                    >
                      <HealthcheckStatusComponent
                        service={service}
                        fontSize="2.15em"
                      />
                    </Grid>
                    <Grid
                      item
                      sm={6}
                      sx={{
                        height: '32px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <FillingStatusComponent
                        service={service}
                        type={FillingStatusComponentType.Circular}
                        enabledLoading={true}
                        styleContainer={{
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                        size="2.15em"
                      />
                    </Grid>
                  </Grid>
                </>
              }
              headerClasses={{
                action: 'headerActionServiceCardClass',
              }}
              options={[
                {
                  name: 'Идентификатор',
                  value: `${service.slug}`,
                },
                {
                  name: 'Владелец',
                  value: service.ownerRef?.name,
                  isUrl: true,
                  externalUrl: false,
                  url: `/teams/${service.ownerRef?.uuid}`,
                },
                {
                  name: 'Репозиторий',
                  isUrl: true,
                  value: service.repositoryRef?.name,
                  url: `/components/repositories/${service?.repositoryRef?.uuid}`,
                  externalUrl: false,
                },
                {
                  name: 'Репозиторий в Gitlab',
                  isUrl: true,
                  value: service.repositoryRef?.slug,
                  url: service.repositoryRef?.url,
                  externalUrl: true,
                },
                {
                  name: 'Стадия',
                  isUrl: false,
                  value: service?.lifecycleStageRef?.name
                    ? `${service.lifecycleStageRef.name}`
                    : 'неизвестно',
                },
                {
                  name: 'Создан из шаблона',
                  value: service.templated ? 'да' : 'нет',
                },
                {
                  name: 'Автообновление шаблона',
                  value: service.templateUpdate ? 'да' : 'нет',
                },
                ...(service.templated
                  ? [
                      {
                        name: 'Шаблон',
                        isUrl: true,
                        value: service.templateRef?.name,
                        url: `/components/service-templates/${service?.templateRef?.uuid}`,
                        externalUrl: false,
                      },
                    ]
                  : []),
              ]}
              linkOpen={`/services/${service.uuid}`}
            />
          ))}
        </Box>
      )}
    </>
  )
}

export default ServicesPanel

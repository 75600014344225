import { HelpOutline } from '@edp/core-common-frontend/dist/packages/mui/icons-material'
import { Grid, TextField, Tooltip } from '@mui/material'
import { forwardRef, useEffect, useState } from 'react'
import { INFRA_SERVICE_SONARQUBE } from 'types/Managers/InfraServices'
import { UserInteractionRemoveButton } from './UserInteractionRemoveButton'
import {
  CustomSelectComponentUUID,
  CustomSelectComponentUUIDOption,
} from '@edp/core-common-frontend'
import { InfraServiceSpec } from '@edp/types'
import { ApiClient } from 'api/ApiClient'

export interface UserInteractionSonarqubeConfigInputProps {
  index: number
  selectedSonarqubeInstance: string
  sonarqubeInfraServices?: InfraServiceSpec[] | undefined
  project: string
  handleChangeSonarqubeInstance: React.Dispatch<React.SetStateAction<string>>
  handleChangeProject: (event: React.ChangeEvent<HTMLInputElement>) => void
  projectDisable?: boolean
  height?: string
  helperText?: string
  removeButton?: boolean
  handleRemove?: () => void
  disabled?: boolean
}

const UserInteractionSonarqubeConfigInput = forwardRef(
  // eslint-disable-next-line
  (props: UserInteractionSonarqubeConfigInputProps, _ref) => {
    const [sonarqubeInfraServices, setSonarqubeInfraServices] = useState<
      InfraServiceSpec[]
    >([])
    const [optionsSonarqubeInfraServices, setOptionsSonarqubeInfraServices] =
      useState<CustomSelectComponentUUIDOption[]>([])

    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<boolean>(false)

    const handleChangeSonarqubeInstanceMock: React.Dispatch<
      React.SetStateAction<string>
    > = () => {}

    useEffect(() => {
      const apiClient = new ApiClient()

      const getSonarqubeInfraServices = async () => {
        try {
          setLoading(true)
          const data = await apiClient.getInfraServicesByType(
            INFRA_SERVICE_SONARQUBE
          )
          setSonarqubeInfraServices(data)
        } catch (e) {
          console.log(e)
          setError(true)
        } finally {
          setLoading(false)
        }
      }

      if (!props.sonarqubeInfraServices) {
        getSonarqubeInfraServices()
      }
    }, [])

    useEffect(() => {
      if (props.sonarqubeInfraServices) {
        setSonarqubeInfraServices(props.sonarqubeInfraServices)
      }
    }, [])

    useEffect(() => {
      const optionsSonarqubeInfraServices: CustomSelectComponentUUIDOption[] =
        sonarqubeInfraServices?.map((o) => ({
          label: o.name,
          uuid: String(o.id),
        })) || []

      setOptionsSonarqubeInfraServices(optionsSonarqubeInfraServices)
    })

    return (
      <>
        <Grid className="UserInteraction-Grid-Remove">
          <Grid className="UserInteraction-SonarqubeConfig-Grid">
            <CustomSelectComponentUUID
              options={optionsSonarqubeInfraServices}
              selected={props.selectedSonarqubeInstance}
              title={'Инстанс'}
              error={error}
              setSelected={
                props.handleChangeSonarqubeInstance ||
                handleChangeSonarqubeInstanceMock
              }
              disabled={props.disabled}
              width="300px"
            />
            <TextField
              key={`${props.index}-project`}
              name={`Проект`}
              label={`Название проекта`}
              value={props.project}
              onChange={props.handleChangeProject}
              disabled={props.projectDisable}
              InputLabelProps={{
                shrink: true,
                sx: {
                  fontSize: '10px',
                  '&.MuiInputLabel-shrink': {
                    transform: 'translate(14px, -5px) scale(0.75)',
                  },
                  marginLeft: '10px',
                },
              }}
              sx={{
                '& .MuiInputBase-root': {
                  height: `${props.height || '40px'}`,
                  width: '360px',
                  marginLeft: '10px',
                },
                '& .MuiOutlinedInput-root': {
                  fontSize: '10px',
                },
                '& .MuiOutlinedInput-input': {
                  fontSize: '15px',
                },
              }}
            />
          </Grid>
          <Tooltip arrow title={props.helperText} placement="top">
            <HelpOutline className="UserInteraction-TwoTextFields-HelpIcon"></HelpOutline>
          </Tooltip>
          {props.removeButton && (
            <UserInteractionRemoveButton handleRemove={props.handleRemove} />
          )}
        </Grid>
      </>
    )
  }
)

UserInteractionSonarqubeConfigInput.displayName =
  'UserInteractionSonarqubeConfigInput'

export default UserInteractionSonarqubeConfigInput

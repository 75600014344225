import { Grid } from '@mui/material'
import { useEffect, useState } from 'react'
import { ApiClient } from 'api/ApiClient'
import { GeneralSettingsSpec } from '@edp/types'
import { EnvironmentSpec } from 'types/Managers/Environments'
import { EditComponentPage } from 'components/EditComponentPage'
import { EntityAction } from 'types/entities'
import Prometheus from './Prometheus'
import SettingsStore from './store'
import CustomVariables from './CustomVariables'

export const SettingsServices = () => {
  const [environments, setEnvironments] = useState<EnvironmentSpec[]>()
  const [loadingEnvironments, setLoadingEnvironments] = useState<boolean>(false)

  const [loadingGeneralSettings, setLoadingGeneralSettings] =
    useState<boolean>(false)

  const [edited, setEdited] = useState<boolean>(false)
  const [failed, setFailed] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')

  useEffect(() => {
    SettingsStore.reset()
  }, [])

  useEffect(() => {
    const apiClient = new ApiClient()

    const getEnvironments = async () => {
      try {
        setLoadingEnvironments(true)
        const data = await apiClient.getEnvironments()
        setEnvironments(data)
      } catch (e) {
        console.log(e)
      } finally {
        setLoadingEnvironments(false)
      }
    }

    getEnvironments()
  }, [])

  useEffect(() => {
    const apiClient = new ApiClient()

    const getGeneralSettings = async () => {
      try {
        setLoadingGeneralSettings(true)
        const data = await apiClient.getGeneralSettings()
        SettingsStore.set(data)
      } catch (e) {
        console.log(e)
      } finally {
        setLoadingGeneralSettings(false)
      }
    }

    getGeneralSettings()
  }, [])

  const handleEdit = async () => {
    setEdited(false)
    setFailed(false)
    setErrorMessage('')

    try {
      await SettingsStore.validate().then(({ isValid }) => {
        if (!isValid) {
          setFailed(true)
          const e = new Error('Все обязательные поля должны быть заполнены')
          return Promise.reject(e)
        }
      })

      const spec: GeneralSettingsSpec = {
        customVariables:
          SettingsStore.$('customVariables').value.length > 0
            ? SettingsStore.$('customVariables').value
            : [],
        prometheusMetrics:
          SettingsStore.$('prometheusMetrics').value.length > 0
            ? SettingsStore.$('prometheusMetrics').value
            : [],
      }

      const apiClient = new ApiClient()

      await apiClient.updateGeneralSettings(spec)
      setEdited(true)
    } catch (e) {
      setFailed(true)
      if (e instanceof Error) {
        setErrorMessage(e.message)
      } else {
        setErrorMessage('Неизвестная ошибка')
      }
    }
  }

  return (
    <>
      <EditComponentPage
        action={EntityAction.Update}
        handleEdit={handleEdit}
        loading={loadingEnvironments}
        disableButton={false}
        edited={edited}
        setEdited={setEdited}
        failed={failed}
        setFailed={setFailed}
        errorMessage={errorMessage}
        editedMessage={'Настройки обновлены'}
      >
        <CustomVariables loadingCustomVariables={loadingGeneralSettings} />
        <Prometheus
          environments={environments || []}
          loadingEnvironments={loadingEnvironments}
          loadingMetrics={loadingGeneralSettings}
        />
        <Grid />
      </EditComponentPage>
    </>
  )
}

export default SettingsServices

import { TeamCustomVariable } from '@edp/types'
import { CircularProgress, Divider, Typography } from '@mui/material'
import { ApiClient } from 'api/ApiClient'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import ServiceStore from './store'
import UserInteractionTwoTextFields from 'components/UserInteraction/UserInteractionTwoTextFields'

export interface AvailableCustomVariablesProps {}

const AvailableCustomVariables = observer(
  (props: AvailableCustomVariablesProps) => {
    const [selectedTeam, setSelectedTeam] = useState<boolean>(false)
    const [teamAvailableCustomVariables, setTeamAvailableCustomVariables] =
      useState<TeamCustomVariable[]>()
    const [
      loadingTeamAvailableCustomVariables,
      setLoadingTeamAvailableCustomVariables,
    ] = useState<boolean>(false)

    useEffect(() => {
      const apiClient = new ApiClient()

      const getTeamPrometheusMetrics = async () => {
        try {
          setLoadingTeamAvailableCustomVariables(true)
          const data = await apiClient.getTeamAvailableCustomVariables(
            ServiceStore.$('owner').value
          )
          setTeamAvailableCustomVariables(data)
        } catch (e) {
          console.log(e)
        } finally {
          setLoadingTeamAvailableCustomVariables(false)
        }
      }

      if (
        ServiceStore.$('owner').value !== '' &&
        ServiceStore.$('owner').value !== undefined &&
        ServiceStore.$('owner').value !== null
      ) {
        console.log(ServiceStore.$('owner').value)
        setSelectedTeam(true)
        getTeamPrometheusMetrics()
      } else {
        setSelectedTeam(false)
        setTeamAvailableCustomVariables([])
      }
    }, [ServiceStore.$('owner').value])

    return (
      <>
        <Divider
          sx={{
            paddingTop: '30px',
          }}
        />
        <Typography
          variant="body1"
          paddingTop="30px"
          className="Typography-Body1"
        >
          Доступные пользовательские переменные
        </Typography>
        {!loadingTeamAvailableCustomVariables ? (
          <>
            {teamAvailableCustomVariables &&
            teamAvailableCustomVariables.length > 0 ? (
              <>
                {teamAvailableCustomVariables.map(
                  (customVariable: TeamCustomVariable, index: number) => {
                    return (
                      <UserInteractionTwoTextFields
                        key={`custom-variable-${index}`}
                        helperText="Пользовательская переменная, задананная в настройках"
                        index={index}
                        first={customVariable.name}
                        second={customVariable.value}
                        firstLabel="Название"
                        secondLabel="Значение"
                        firstDisabled={true}
                        secondDisabled={true}
                      />
                    )
                  }
                )}
              </>
            ) : (
              <>
                {!selectedTeam ? (
                  <>
                    <Typography>
                      Пожалуйста, укажите владельца сервиса
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography>Переменные отсутствуют</Typography>
                  </>
                )}
              </>
            )}
          </>
        ) : (
          <>
            <CircularProgress size={20} />
          </>
        )}
      </>
    )
  }
)

export default AvailableCustomVariables

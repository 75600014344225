import { CustomCardHeader } from '@edp/core-common-frontend'
import {
  Card,
  CardContent,
  Chip,
  Grid,
  LinearProgress,
  List,
  ListItem,
  Tooltip,
  Typography,
} from '@edp/core-common-frontend/dist/packages/mui/material'
import {
  PrometheusQueryResultVectorTypeSpec,
  ServicePrometheusMetricResultMetricSpec,
  ServicePrometheusMetricResultSpec,
} from '@edp/types'
import { ApiClient } from 'api/ApiClient'
import { ReactNode, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

export interface PrometheusPanelProps {}

export const PrometheusPanel = (props: PrometheusPanelProps) => {
  const { uuid } = useParams()

  const [metrics, setMetrics] = useState<ServicePrometheusMetricResultSpec[]>(
    []
  )
  const [loading, setLoading] = useState<boolean>(false)

  const apiClient = new ApiClient()

  useEffect(() => {
    const getMetrics = async () => {
      try {
        if (uuid) {
          setLoading(true)
          const data = await apiClient.getServicePrometheusMetrics(uuid)
          setMetrics(data)
        }
      } catch (e) {
        console.error(e)
      } finally {
        setLoading(false)
      }
    }
    getMetrics()
  }, [])

  const formattingResultValue = (
    value: ServicePrometheusMetricResultMetricSpec
  ) => {
    const tmpValue = value.metric.data
      .result as Array<PrometheusQueryResultVectorTypeSpec>

    if (tmpValue.length > 0 && tmpValue[0].value !== undefined) {
      if (String(tmpValue[0].value[1]) === 'NaN') {
        return <>0 {value.unit}</>
      } else {
        return (
          <>
            {Number(tmpValue[0].value[1]).toFixed(2)} {value.unit}
          </>
        )
      }
    } else {
      return <>Нет данных</>
    }
  }

  const generateItem = (data: ServicePrometheusMetricResultMetricSpec) => {
    const getNumber = (): number => {
      const tmpValue = data.metric.data
        .result as Array<PrometheusQueryResultVectorTypeSpec>

      if (tmpValue.length > 0 && tmpValue[0].value !== undefined) {
        if (String(tmpValue[0].value[1]) === 'NaN') {
          return 0
        } else {
          return Number(Number(tmpValue[0].value[1]).toFixed(2))
        }
      } else {
        return -1
      }
    }

    const getElement = (): ReactNode => {
      const value: number = getNumber()
      const invalidData: number = -1

      if (value === invalidData) {
        return <Chip label="неизвестно" color="warning" />
      } else if (value < Number(data.threshold)) {
        return (
          <Tooltip
            title={`Пороговое значение: ${data.threshold}`}
            placement="top"
            arrow
          >
            <Chip label="все хорошо" color="success" />
          </Tooltip>
        )
      } else {
        return (
          <Tooltip
            title={`Пороговое значение: ${data.threshold}`}
            placement="top"
            arrow
          >
            <Chip label="проблемы" color="error" />
          </Tooltip>
        )
      }
    }

    return (
      <ListItem>
        <Grid
          container
          spacing={2}
          sx={{
            display: 'flex',
            alignContent: 'center',
            alignItems: 'center',
          }}
        >
          <Grid item sm={5}>
            <Typography>{data.name}: </Typography>
          </Grid>
          <Grid item sm={3}>
            {getElement()}
          </Grid>
          <Grid item sm={4}>
            <Typography>{formattingResultValue(data)}</Typography>
          </Grid>
        </Grid>
      </ListItem>
    )
  }

  return (
    <>
      {!loading ? (
        <Grid container spacing={2}>
          {Array.from(metrics.entries()).map(([key, value]) => {
            return (
              <Grid item sm={4}>
                <Card sx={{ boxShadow: '1' }}>
                  <CustomCardHeader
                    height="80px"
                    title={`Окружение: ${value.environment.name}`}
                    description={value.environment.description}
                  />
                  <CardContent>
                    <List>
                      {value.metrics.map((data, key) => {
                        return generateItem(data)
                      })}
                    </List>
                  </CardContent>
                </Card>
              </Grid>
            )
          })}
        </Grid>
      ) : (
        <>
          <LinearProgress />
        </>
      )}
    </>
  )
}

export default PrometheusPanel

import {
  Avatar,
  useTheme,
} from '@edp/core-common-frontend/dist/packages/mui/material'
import { React } from '@edp/core-common-frontend/dist/packages/react'

export const Rating = ({
  rating,
  hideValue,
}: {
  rating?: string
  hideValue?: boolean
}) => {
  const theme = useTheme()
  const ratingProp = React.useMemo(() => {
    switch (rating) {
      case '1.0':
        return {
          name: 'A',
          background: theme.palette.success.light,
        }

      case '2.0':
        return {
          name: 'B',
          background: theme.palette.info.light,
        }

      case '3.0':
        return {
          name: 'C',
          background: theme.palette.warning.light,
        }

      case '4.0':
        return {
          name: 'D',
          background: theme.palette.error.light,
        }

      case '5.0':
        return {
          name: 'E',
          background: theme.palette.error.light,
        }

      default:
        return {
          name: '',
          background: theme.palette.error.light,
        }
    }
  }, [
    rating,
    theme.palette.error.light,
    theme.palette.info.light,
    theme.palette.success.light,
    theme.palette.warning.light,
  ])

  return (
    <Avatar
      style={{
        backgroundColor: ratingProp.background,
        width: '30px',
        height: '30px',
      }}
    >
      {!hideValue && ratingProp.name}
    </Avatar>
  )
}

import {
  InfraServiceSpec,
  TeamSonarqubeConfigInstanceSpec,
  TeamSpec,
} from '@edp/types'
import { CircularProgress, Grid, Typography } from '@mui/material'
import { ApiClient } from 'api/ApiClient'
import { UserInteractionAddButton } from 'components/UserInteraction/UserInteractionAddButton'
import UserInteractionSonarqubeConfigInput from 'components/UserInteraction/UserInteractionSonarqubeConfigInput'
import { useEffect, useState } from 'react'
import { INFRA_SERVICE_SONARQUBE } from 'types/Managers/InfraServices'

interface SonarQubeInstanceProps {
  index: number
  selectedSonarqubeInstanceInitID: number
  project: string
  sonarqubeInfraServices?: InfraServiceSpec[]
  team: TeamSpec
  setTeam: React.Dispatch<React.SetStateAction<TeamSpec>>
}

interface SonarQubeProps {
  team: TeamSpec
  setTeam: React.Dispatch<React.SetStateAction<TeamSpec>>
}

export const SonarQubeInstance = (props: SonarQubeInstanceProps) => {
  const [selectedSonarqubeInstance, setSelectedSonarqubeInstance] =
    useState<number>(-1)

  useEffect(() => {
    setSelectedSonarqubeInstance(props.selectedSonarqubeInstanceInitID)
  }, [props.selectedSonarqubeInstanceInitID])

  const handleChangeSonarqubeInstance = (index: number, value: string) => {
    const team = { ...props.team }

    if (team.sonarqubeConfigRef !== undefined) {
      team.sonarqubeConfigRef.instances[index].infraService = Number(value)
      setSelectedSonarqubeInstance(Number(value))
      props.setTeam(team)
    }
  }

  const handleChangeProject = (index: number, value: string) => {
    const team = { ...props.team }

    if (team.sonarqubeConfigRef !== undefined) {
      team.sonarqubeConfigRef.instances[index].project = value.toString()
      props.setTeam(team)
    }
  }

  const handleRemoveInstance = (index: number) => {
    const team = { ...props.team }

    if (team.sonarqubeConfigRef !== undefined) {
      team.sonarqubeConfigRef.instances.splice(index, 1)
      props.setTeam(team)
    }
  }

  return (
    <>
      <UserInteractionSonarqubeConfigInput
        key={`sonarqube-config-metric-${props.index}`}
        index={props.index}
        selectedSonarqubeInstance={String(selectedSonarqubeInstance)}
        project={props.project}
        handleChangeSonarqubeInstance={(e) => {
          handleChangeSonarqubeInstance(props.index, e.toString())
        }}
        handleChangeProject={(e) => {
          handleChangeProject(props.index, e.target.value)
        }}
        projectDisable={false}
        removeButton
        handleRemove={() => {
          handleRemoveInstance(props.index)
        }}
        sonarqubeInfraServices={props.sonarqubeInfraServices}
      />
    </>
  )
}

export const SonarQube = (props: SonarQubeProps) => {
  const [sonarqubeInfraServices, setSonarqubeInfraServices] = useState<
    InfraServiceSpec[]
  >([])
  const [loadingSonarqubeInfraServices, setLoadingSonarqubeInfraServices] =
    useState<boolean>(false)

  useEffect(() => {
    const apiClient = new ApiClient()

    const getSonarqubeInfraServices = async () => {
      try {
        setLoadingSonarqubeInfraServices(true)
        const data = await apiClient.getInfraServicesByType(
          INFRA_SERVICE_SONARQUBE
        )
        setSonarqubeInfraServices(data)
      } catch (e) {
        console.log(e)
      } finally {
        setLoadingSonarqubeInfraServices(false)
      }
    }

    getSonarqubeInfraServices()
  }, [])

  const handleAddInstance = () => {
    const team = { ...props.team }

    if (team.sonarqubeConfigRef?.instances !== undefined) {
      if (team.sonarqubeConfigRef.instances.length !== 0) {
        if (
          team.sonarqubeConfigRef.instances[
            team.sonarqubeConfigRef.instances.length - 1
          ].infraService !== -1 ||
          team.sonarqubeConfigRef.instances[
            team.sonarqubeConfigRef.instances.length - 1
          ].project !== ''
        ) {
          team.sonarqubeConfigRef.instances.push({
            team: team.uuid,
            infraService: -1,
            project: '',
          })
        }
      } else {
        team.sonarqubeConfigRef.instances.push({
          team: team.uuid,
          infraService: -1,
          project: '',
        })
      }
    } else {
      team.sonarqubeConfigRef = {
        team: team.uuid,
        instances: [
          {
            team: team.uuid,
            infraService: -1,
            project: '',
          },
        ],
      }
    }

    props.setTeam(team)
  }

  return (
    <>
      <Typography
        variant="body1"
        paddingTop="30px"
        className="Typography-Body1"
      >
        SonarQube
      </Typography>
      <Grid
        sx={{
          marginBottom: '10px',
        }}
      >
        {!loadingSonarqubeInfraServices ? (
          <>
            {props.team.sonarqubeConfigRef?.instances && (
              <>
                {props.team.sonarqubeConfigRef.instances.length > 0 &&
                  props.team.sonarqubeConfigRef.instances.map(
                    (
                      instance: TeamSonarqubeConfigInstanceSpec,
                      index: number
                    ) => {
                      return (
                        <SonarQubeInstance
                          index={index}
                          selectedSonarqubeInstanceInitID={
                            instance.infraService
                          }
                          project={instance.project}
                          team={props.team}
                          setTeam={props.setTeam}
                          sonarqubeInfraServices={sonarqubeInfraServices}
                        />
                      )
                    }
                  )}
              </>
            )}
            <UserInteractionAddButton handleAdd={handleAddInstance} />
          </>
        ) : (
          <>
            <CircularProgress size={20} />
          </>
        )}
      </Grid>
    </>
  )
}

export default SonarQube

import {
  createService,
  getService,
  updateService,
} from '@edp/core-common-frontend'
import {
  GeneralSettingsSpec,
  ServiceSpec,
  TeamPresettingsConfigSpec,
} from '@edp/types'
import { EditComponentPage } from 'components/EditComponentPage'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { EntityAction } from 'types/entities'
import BasicInfo from './BasicInfo'
import Secrets from './Secrets'
import { Template } from './Template'
import TemplateActions from './TemplateActions'
import ServiceStore from './store'
import TerraformActions from './TerraformActions'
import { Grid } from '@mui/material'
import Kubernetes from './Kubernetes'
import Lifecycle from './Lifecycle'
import Swagger from './Swagger'
import HelmRelease from './HelmReleases'
import Prometheus from './Prometheus'
import { ApiClient } from 'api/ApiClient'
import AvailableCustomVariables from './AvailableCustomVariables'
import Sonarqube from './Sonarqube'

export interface EditServicePageProps {
  action: EntityAction
}

export const EditServicePage = observer((props: EditServicePageProps) => {
  const { uuid } = useParams()
  const navigate = useNavigate()

  const [generalSettings, setGeneralSettings] = useState<GeneralSettingsSpec>()
  const [loadingGeneralSettings, setLoadingGeneralSettings] =
    useState<boolean>(true)

  const [teamPresettings, setTeamPresettings] =
    useState<TeamPresettingsConfigSpec>()
  const [loadingTeamPresettings, setLoadingTeamPresettings] =
    useState<boolean>(false)

  const [edited, setEdited] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [failed, setFailed] = useState<boolean>(false)
  const [disableButton, setDisableButton] = useState<boolean>(false)

  useEffect(() => {
    const apiClient = new ApiClient()

    const getTeamPresettings = async () => {
      try {
        setLoadingTeamPresettings(true)
        const data = await apiClient.getTeamPresettings(
          ServiceStore.$('owner').value
        )
        setTeamPresettings(data)
      } catch (e) {
        console.log(e)
      } finally {
        setLoadingTeamPresettings(false)
      }
    }

    if (ServiceStore.$('owner').value) {
      getTeamPresettings()
    } else {
      setTeamPresettings(undefined)
    }
  }, [ServiceStore.$('owner').value])

  useEffect(() => {
    const apiClient = new ApiClient()

    const getGeneralSettings = async () => {
      try {
        setLoadingGeneralSettings(true)
        const data = await apiClient.getGeneralSettings()
        setGeneralSettings(data)
      } catch (e) {
        console.log(e)
      } finally {
        setLoadingGeneralSettings(false)
      }
    }

    getGeneralSettings()
  }, [])

  useEffect(() => {
    ServiceStore.reset()
  }, [])

  const fetchData = async () => {
    try {
      if (uuid) {
        const data = await getService(uuid)
        ServiceStore.set(data)
      }
    } catch (e) {
      console.error('Ошибка при получении данных:', e)
    }
  }

  useEffect(() => {
    fetchData()
  }, [uuid])

  useEffect(() => {
    setDisableButton(!ServiceStore.$('templateValid').value)
  }, [ServiceStore.$('templateValid').value])

  useEffect(() => {
    if (
      ServiceStore.$('templated').value &&
      props.action === EntityAction.Create
    ) {
      if (ServiceStore.$('templateVersion').value == '') {
        setDisableButton(true)
      } else {
        setDisableButton(false)
      }
    } else {
      setDisableButton(false)
    }
  }, [ServiceStore.$('templated').value])

  useEffect(() => {
    if (
      ServiceStore.$('templated').value &&
      props.action === EntityAction.Create
    ) {
      if (ServiceStore.$('templateVersion').value == '') {
        setDisableButton(true)
      } else {
        setDisableButton(false)
      }
    }
  }, [ServiceStore.$('templateVersion').value])

  const handleEdit = async () => {
    setEdited(false)
    setFailed(false)
    setErrorMessage('')
    setLoading(true)

    try {
      await ServiceStore.validate().then(({ isValid }) => {
        if (!isValid) {
          setFailed(true)
          const e = new Error('Все обязательные поля должны быть заполнены')
          return Promise.reject(e)
        }
      })

      const spec: ServiceSpec = {
        name: ServiceStore.$('name').value,
        slug: ServiceStore.$('slug').value,
        description: ServiceStore.$('description').value,
        owner: ServiceStore.$('owner').value,
        repository: ServiceStore.$('repository').value,
        repositoryUrl: ServiceStore.$('repositoryUrl').value,
        templated: ServiceStore.$('templated').value,
        template: ServiceStore.$('template').value,
        templateVersion: ServiceStore.$('templateVersion').value,
        templateUpdate: ServiceStore.$('templateUpdate').value,
        templateRepositoryVariables: ServiceStore.$(
          'templateRepositoryVariables'
        ).value,
        updatesBranch: ServiceStore.$('updatesBranch').value,
        actionsVariables: ServiceStore.$('actionsVariables').value,
        gitlabProjectLevelVariables: ServiceStore.$(
          'gitlabProjectLevelVariables'
        ).value,
        branches: ServiceStore.$('branches').value,
        vault: {
          secrets: ServiceStore.$('vault.secrets').value,
        },
        terraformActions:
          ServiceStore.$('terraformActions').value.length > 0
            ? ServiceStore.$('terraformActions').value.filter(
                (item: string) => item !== ''
              )
            : [],
        helmReleases: ServiceStore.$('helmReleases').value,
        namespaces: ServiceStore.$('namespaces').value,
        lifecycleStage: ServiceStore.$('lifecycleStage').value,
        swaggerConfig: ServiceStore.$('swaggerConfig').value,
        swaggerConfigRef: {
          service: ServiceStore.$('swaggerConfigRef.service').value,
          type: ServiceStore.$('swaggerConfigRef.type').value,
          links:
            ServiceStore.$('swaggerConfigRef.links').value.length > 0
              ? ServiceStore.$('swaggerConfigRef.links').value
              : [],
        },
        kubernetesConfig: ServiceStore.$('kubernetesConfig').value,
        kubernetesConfigRef: {
          service: ServiceStore.$('kubernetesConfigRef.service').value,
          objects:
            ServiceStore.$('kubernetesConfigRef.objects').value.length > 0
              ? ServiceStore.$('kubernetesConfigRef.objects').value
              : [],
        },
        prometheusConfig: ServiceStore.$('prometheusConfig').value,
        prometheusConfigRef: {
          service: ServiceStore.$('prometheusConfigRef.service').value,
          metrics:
            ServiceStore.$('prometheusConfigRef.metrics').value.length > 0
              ? ServiceStore.$('prometheusConfigRef.metrics').value
              : [],
        },
        sonarqubeConfigRef: {
          service: ServiceStore.$('sonarqubeConfigRef.service').value,
          instances:
            ServiceStore.$('sonarqubeConfigRef.instances').value.length > 0
              ? ServiceStore.$('sonarqubeConfigRef.instances').value
              : [],
        },
      }

      if (props.action === EntityAction.Create) {
        await createService(spec)
      }

      if (props.action === EntityAction.Update) {
        spec.uuid = uuid
        await updateService(spec)
      }

      setEdited(true)
      navigate('/services')
    } catch (e) {
      setFailed(true)
      if (e instanceof Error) {
        setErrorMessage(e.message)
      } else {
        setErrorMessage('Неизвестная ошибка')
      }
    } finally {
      setLoading(false)
    }
  }

  let editedMessage

  if (props.action === EntityAction.Create) {
    editedMessage = 'Сервис создан'
  }

  if (props.action === EntityAction.Update) {
    editedMessage = 'Сервис обновлен'
  }

  return (
    <>
      <EditComponentPage
        action={props.action}
        handleEdit={handleEdit}
        loading={loading}
        disableButton={disableButton}
        edited={edited}
        setEdited={setEdited}
        failed={failed}
        setFailed={setFailed}
        errorMessage={errorMessage}
        editedMessage={editedMessage}
      >
        <>
          <BasicInfo disabled={props.action === EntityAction.Update} />
          <AvailableCustomVariables />
          <Template
            disabled={props.action === EntityAction.Update}
            visible={props.action === EntityAction.Create}
          />
          <TemplateActions disabled={props.action === EntityAction.Update} />
          {/*<TerraformActions entityAction={props.action} />*/}
          <Kubernetes
            action={props.action}
            disabled={false}
            teamPresettings={teamPresettings}
            loadingTeamPresettings={loadingTeamPresettings}
          />
          <HelmRelease
            action={props.action}
            disabled={false}
            teamPresettings={teamPresettings}
            loadingTeamPresettings={loadingTeamPresettings}
          />
          <Swagger disabled={false} action={props.action} />
          <Lifecycle disabled={props.action === EntityAction.Create} />
          <Secrets disabled={props.action === EntityAction.Update} />
          <Sonarqube />
          <Prometheus
            generalSettings={generalSettings}
            loadingGeneralSettings={loadingGeneralSettings}
          />
          <Grid />
        </>
      </EditComponentPage>
    </>
  )
})

export default EditServicePage

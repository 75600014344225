import {
  CustomVariableBaseSpec,
  GeneralSettingsSpec,
  TeamCustomVariable,
  TeamSpec,
} from '@edp/types'
import { CircularProgress, Divider, Grid, Typography } from '@mui/material'
import { UserInteractionAddButton } from 'components/UserInteraction/UserInteractionAddButton'
import UserInteractionTwoTextFields from 'components/UserInteraction/UserInteractionTwoTextFields'
import React, { useState } from 'react'

export interface CustomVariablesProps {
  generalSettings?: GeneralSettingsSpec
  loadingGeneralSettings: boolean
  team: TeamSpec
  setTeam: React.Dispatch<React.SetStateAction<TeamSpec>>
}

interface TeamCustomVariableItemProps {
  index: number
  customVariable: TeamCustomVariable
  team: TeamSpec
  setTeam: React.Dispatch<React.SetStateAction<TeamSpec>>
}

const TeamCustomVariableItem = (props: TeamCustomVariableItemProps) => {
  const validateName = (value: string) => {
    if (value === 'EDP_') {
      return true
    }

    const pattern = /^EDP_[A-Za-z0-9_]+$/
    return pattern.test(value)
  }

  const handleChangeName = (index: number, value: string) => {
    if (!validateName(value)) {
      return
    }

    const team = { ...props.team }

    if (team.customVariablesConfigRef !== undefined) {
      team.customVariablesConfigRef.customVariables[index].name = value
      props.setTeam(team)
    }
  }

  const handleChangeValue = (index: number, value: string) => {
    const team = { ...props.team }

    if (team.customVariablesConfigRef !== undefined) {
      team.customVariablesConfigRef.customVariables[index].value = value
      props.setTeam(team)
    }
  }

  const handleRemoveCustomVariable = (index: number) => {
    const team = { ...props.team }

    if (team.customVariablesConfigRef !== undefined) {
      team.customVariablesConfigRef.customVariables.splice(index, 1)
      props.setTeam(team)
    }
  }

  return (
    <UserInteractionTwoTextFields
      key={`team-custom-variable-${props.index}`}
      helperText="Пользовательская переменная, заданная на уровне команды"
      index={props.index}
      first={props.customVariable.name}
      firstLabel="Название"
      handleChangeFirst={(e) => {
        handleChangeName(props.index, e.target.value)
      }}
      second={props.customVariable.value}
      secondLabel="Значение"
      handleChangeSecond={(e) => {
        handleChangeValue(props.index, e.target.value)
      }}
      removeButton
      handleRemove={() => {
        handleRemoveCustomVariable(props.index)
      }}
    />
  )
}

export const CustomVariables = (props: CustomVariablesProps) => {
  const [loading, setLoading] = useState<boolean>(false)

  const handleAddCustomVariable = () => {
    const team = { ...props.team }
    if (team.customVariablesConfigRef?.customVariables !== undefined) {
      if (team.customVariablesConfigRef.customVariables.length !== 0) {
        if (
          (team.customVariablesConfigRef.customVariables[
            team.customVariablesConfigRef.customVariables.length - 1
          ].name !== '' &&
            team.customVariablesConfigRef.customVariables[
              team.customVariablesConfigRef.customVariables.length - 1
            ].name !== 'EDP_') ||
          team.customVariablesConfigRef.customVariables[
            team.customVariablesConfigRef.customVariables.length - 1
          ].value !== ''
        ) {
          team.customVariablesConfigRef.customVariables.push({
            team: props.team.uuid ? props.team.uuid : '',
            name: 'EDP_',
            value: '',
          })
        }
      } else {
        team.customVariablesConfigRef.customVariables.push({
          team: team.uuid ? props.team.uuid : '',
          name: 'EDP_',
          value: '',
        })
      }
    } else {
      team.customVariablesConfigRef = {
        team: team.uuid,
        customVariables: [
          {
            team: team.uuid ? props.team.uuid : '',
            name: 'EDP_',
            value: '',
          },
        ],
      }
    }

    props.setTeam(team)
  }

  return (
    <>
      <Typography
        variant="body1"
        paddingTop="30px"
        className="Typography-Body1"
      >
        Пользовательские переменные
      </Typography>
      {!loading && !props.loadingGeneralSettings ? (
        <>
          {props.generalSettings &&
            props.generalSettings.customVariables.length > 0 && (
              <Grid
                sx={{
                  marginBottom: '10px',
                }}
              >
                <Grid
                  sx={{
                    width: '670px',
                  }}
                >
                  <Divider>Общие пользовательские переменные</Divider>
                </Grid>
                {props.generalSettings.customVariables.map(
                  (customVariable: CustomVariableBaseSpec, index: number) => {
                    return (
                      <UserInteractionTwoTextFields
                        key={`general-custom-variable-${index}`}
                        helperText="Пользовательская переменная, заданная на уровне EDP"
                        index={index}
                        first={customVariable.name}
                        firstLabel="Название"
                        firstDisabled={true}
                        second={customVariable.value}
                        secondLabel="Значение"
                        secondDisabled={true}
                      />
                    )
                  }
                )}
              </Grid>
            )}
          <Grid
            sx={{
              width: '670px',
            }}
          >
            <Divider>Пользовательские переменные команды</Divider>
          </Grid>
          {props.team.customVariablesConfigRef &&
            props.team.customVariablesConfigRef?.customVariables && (
              <>
                {props.team.customVariablesConfigRef?.customVariables.length >
                  0 &&
                  props.team.customVariablesConfigRef?.customVariables.map(
                    (customVariable: TeamCustomVariable, index: number) => {
                      return (
                        <TeamCustomVariableItem
                          index={index}
                          customVariable={customVariable}
                          team={props.team}
                          setTeam={props.setTeam}
                        />
                      )
                    }
                  )}
              </>
            )}
          <UserInteractionAddButton handleAdd={handleAddCustomVariable} />
        </>
      ) : (
        <>
          <CircularProgress size={20} />
        </>
      )}
    </>
  )
}

export default CustomVariables

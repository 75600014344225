import {
  GeneralCustomVariableSpec,
  GeneralPrometheusConfigMetricSpec,
} from '@edp/types'
import MobxReactForm from 'mobx-react-form'
import dvr from 'mobx-react-form/lib/validators/DVR'
import validatorjs from 'validatorjs'

const customVariablesEmpty: GeneralCustomVariableSpec[] = []
const metricEmpty: GeneralPrometheusConfigMetricSpec[] = []

const plugins = {
  dvr: dvr(validatorjs),
}

const fields = {
  customVariables: {
    value: [],
    fields: customVariablesEmpty,
  },
  prometheusMetrics: {
    value: [],
    fields: metricEmpty,
  },
}

export default new MobxReactForm({ fields }, { plugins })

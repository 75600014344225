import { ToolCard } from './ToolCard'
import { Box } from '@mui/material'
import { ApiClient } from 'api/ApiClient'
import { ExploreTool } from './types'
import { useState, useEffect } from 'react'
import {
  INFRA_SERVICE_ARGOCD,
  INFRA_SERVICE_CUSTOM,
  INFRA_SERVICE_DEFECTDOJO,
  INFRA_SERVICE_GITLAB,
  INFRA_SERVICE_GRAFANA,
  INFRA_SERVICE_HARBOR,
  INFRA_SERVICE_MATTERMOST,
  INFRA_SERVICE_NEXUS,
  INFRA_SERVICE_OPENSEARCH,
  INFRA_SERVICE_OPENSEARCH_DASHBOARDS,
  INFRA_SERVICE_POLARIS,
  INFRA_SERVICE_PROMETHEUS,
  INFRA_SERVICE_SONARQUBE,
  INFRA_SERVICE_VAULT,
} from 'types/Managers/InfraServices'
import { InfraServiceSpec } from '@edp/types'
import React from 'react'

const ExplorePage = () => {
  const [infraServices, setInfraServices] = useState<InfraServiceSpec[]>([])

  const apiClient = new ApiClient()

  useEffect(() => {
    const getInfraServices = async () => {
      try {
        const data = await apiClient.getInfraServices()
        setInfraServices(data)
      } catch (e) {
        console.error(e)
      }
    }

    getInfraServices()
  }, [])

  const explorePlatformServices: ExploreTool[] = infraServices
    .map((infraService) => {
      switch (infraService.type) {
        case INFRA_SERVICE_GRAFANA:
          return {
            title: 'Grafana',
            description: infraService.description
              ? infraService.description
              : 'Система визуализации метрик',
            url: infraService.url,
            image: '/static/images/infrastructure-services/grafana-logo.svg',
            tags: ['metrics', 'dashboards'],
          }
        case INFRA_SERVICE_PROMETHEUS:
          return {
            title: 'Prometheus',
            description: infraService.description
              ? infraService.description
              : 'Система сбора метрик',
            url: infraService.url,
            image: '/static/images/infrastructure-services/prometheus-logo.png',
            tags: ['metrics'],
          }
        case INFRA_SERVICE_OPENSEARCH:
          return {
            title: 'OpenSearch',
            description: infraService.description
              ? infraService.description
              : 'Система логирования',
            url: infraService.url,
            image: '/static/images/infrastructure-services/opensearch-logo.png',
            tags: ['logging'],
          }
        case INFRA_SERVICE_OPENSEARCH_DASHBOARDS:
          return {
            title: 'OpenSearch Dashboards',
            description: infraService.description
              ? infraService.description
              : 'Система логирования',
            url: infraService.url,
            image: '/static/images/infrastructure-services/opensearch-logo.png',
            tags: ['logging', 'dashboards'],
          }
        case INFRA_SERVICE_CUSTOM:
          return {
            title: 'Custom API',
            description: infraService.description
              ? infraService.description
              : '',
            url: infraService.url,
            image: '',
            tags: ['api', 'custom'],
          }
        case INFRA_SERVICE_HARBOR:
          return {
            title: 'Harbor',
            description: infraService.description
              ? infraService.description
              : 'Хранилище артефактов',
            url: infraService.url,
            image: '/static/images/infrastructure-services/harbor-logo.png',
            tags: ['artifacts'],
          }
        case INFRA_SERVICE_NEXUS:
          return {
            title: 'Nexus',
            description: infraService.description
              ? infraService.description
              : 'Хранилище артефактов',
            url: infraService.url,
            image: '/static/images/infrastructure-services/nexus-logo.webp',
            tags: ['artifacts'],
          }
        case INFRA_SERVICE_MATTERMOST:
          return {
            title: 'Mattermost',
            description: infraService.description
              ? infraService.description
              : 'Платформа для коммуникации',
            url: infraService.url,
            image: '/static/images/infrastructure-services/mattermost-logo.svg',
            tags: ['collaboration'],
          }
        case INFRA_SERVICE_VAULT:
          return {
            title: 'HashiCorp Vault',
            description: infraService.description
              ? infraService.description
              : 'Хранилище секретов',
            url: infraService.url,
            image: '/static/images/infrastructure-services/vault-logo.png',
            tags: ['security', 'secrets'],
          }
        case INFRA_SERVICE_GITLAB:
          return {
            title: 'Gitlab',
            description: infraService.description
              ? infraService.description
              : 'Хранилище исходного кода',
            url: infraService.url,
            image: '/static/images/infrastructure-services/gitlab-logo.svg',
            tags: ['vcs', 'ci/cd'],
          }
        case INFRA_SERVICE_DEFECTDOJO:
          return {
            title: 'DefectDojo',
            description: infraService.description
              ? infraService.description
              : 'Система управления уязвимостями',
            url: infraService.url,
            image: '/static/images/infrastructure-services/defectdojo-logo.png',
            tags: ['security', 'vulnerabilities'],
          }
        case INFRA_SERVICE_SONARQUBE:
          return {
            title: 'SonarQube',
            description: infraService.description
              ? infraService.description
              : 'Система анализа кода',
            url: infraService.url,
            image: '/static/images/infrastructure-services/sonarqube-logo.svg',
            tags: ['sast'],
          }
        case INFRA_SERVICE_ARGOCD:
          return {
            title: 'Argo CD',
            description: infraService.description
              ? infraService.description
              : 'GitOps инструмент для управления состоянием Kubernetes кластеров',
            url: infraService.url,
            image: '/static/images/infrastructure-services/argocd-logo.png',
            tags: ['gitops'],
          }
        case INFRA_SERVICE_POLARIS:
          return {
            title: 'Polaris dashboard',
            description: infraService.description
              ? infraService.description
              : 'Визуализация состояния кластера',
            url: infraService.url,
            image:
              '/static/images/infrastructure-services/polaris-dashboard-logo.png',
            tags: ['security', 'dashboards'],
          }
        default:
          return {
            title: 'Unknown',
            description: 'unknown',
            url: infraService.url,
            image: '',
            tags: ['unknown'],
          }
      }
    })
    .filter((exploreTool) => exploreTool !== null)

  return (
    <>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(22em, 1fr))',
          gridAutoRows: '1fr',
          gridGap: 10,
        }}
      >
        {explorePlatformServices.map((tool, index) => (
          <ToolCard key={index} card={tool} />
        ))}
      </Box>
    </>
  )
}

export default ExplorePage

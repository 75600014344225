import { ApiClient } from 'api/ApiClient'
import { getAuthType, getJWT } from 'api/utils'
import { InfraServiceUrl } from 'types/Managers/InfraServices'
import { InfraServiceConfigurationSpec } from 'types/entities'
import { InfraServiceCheckConfiguration } from 'types/other'
import { handleResponse } from '@edp/core-common-frontend'
import { InfraServiceSpec } from '@edp/types'

export async function createInfraService(
  this: ApiClient,
  infraService: InfraServiceConfigurationSpec
): Promise<any> {
  const url = `${this.serverUrl}/infra-service`
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
    body: JSON.stringify(infraService),
  })

  const status: Promise<any> = await handleResponse(response)
  return status
}

export async function getInfraServicesByType(
  this: ApiClient,
  serviceType: string
): Promise<InfraServiceSpec[]> {
  const url = `${this.serverUrl}/infra-services?type=${serviceType}`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })

  const result: Promise<Array<any>> = await handleResponse(response)
  return result
}

export async function getInfraServices(
  this: ApiClient
): Promise<InfraServiceSpec[]> {
  const url = `${this.serverUrl}/infra-services`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })

  const result: Promise<Array<any>> = await handleResponse(response)
  return result
}

export async function getInfraServiceInfo(
  this: ApiClient,
  type: string,
  name: string
): Promise<any> {
  const url = `${this.serverUrl}/infra-service/info?type=${type}&name=${name}`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })

  const result: Promise<any> = await handleResponse(response)
  return result
}

export async function getInfraService(
  this: ApiClient,
  type: string,
  name: string
): Promise<InfraServiceSpec> {
  const url = `${this.serverUrl}/infra-service?type=${type}&name=${name}`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })

  const result: Promise<any> = await handleResponse(response)
  return result
}

export async function getInfraServiceUrl(
  this: ApiClient,
  id: number
): Promise<InfraServiceUrl> {
  const url = `${this.serverUrl}/infra-service/${id}/url`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })

  const result: InfraServiceUrl = await handleResponse(response)
  return result
}

export async function deleteInfraService(
  this: ApiClient,
  serviceType: string,
  serviceName: string
): Promise<any> {
  const url = `${this.serverUrl}/infra-service?type=${serviceType}&name=${serviceName}`
  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })

  const result: Promise<any> = await handleResponse(response)
  return result
}

export async function checkConnectInfraService(
  this: ApiClient,
  config: InfraServiceCheckConfiguration
): Promise<any> {
  const url = `${this.serverUrl}/infra-service/check?type=${config.type}&url=${config.url}&token=${config.token}`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'X-Auth-Type': `${getAuthType()}`,
      Authorization: `Bearer ${getJWT()}`,
    },
  })

  const status: Promise<any> = await handleResponse(response)
  return status
}

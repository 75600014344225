import { observer } from 'mobx-react-lite'
import { GeneralPrometheusConfigMetricSpec } from '@edp/types'
import { CircularProgress, Grid, Typography } from '@mui/material'
import { UserInteractionAddButton } from 'components/UserInteraction/UserInteractionAddButton'
import UserInteractionPrometheusMetricInput from 'components/UserInteraction/UserInteractionPrometheusMetricInput'
import { EnvironmentSpec } from 'types/Managers/Environments'
import SettingsStore from './store'
import { useEffect, useState } from 'react'
import { runInAction } from 'mobx'

export interface PrometheusProps {
  environments: EnvironmentSpec[]
  loadingEnvironments: boolean
  loadingMetrics: boolean
}

interface PrometheusMetricItemProps {
  environments: EnvironmentSpec[]
  metric: GeneralPrometheusConfigMetricSpec
  index: number
  selectedRadioButton: number
  setSelectedRadioButton: (index: number) => void
}

const PrometheusMetricItem = observer((props: PrometheusMetricItemProps) => {
  const [error, setError] = useState<boolean>(false)

  const handleChangeEnvironment = (index: number, value: string) => {
    runInAction(() => {
      const metrics: GeneralPrometheusConfigMetricSpec[] =
        SettingsStore.$('prometheusMetrics').value

      metrics[index].environment = value
      SettingsStore.$('prometheusMetrics').set(metrics)
    })
  }

  const handleChangeName = (index: number, value: string) => {
    runInAction(() => {
      const metrics: GeneralPrometheusConfigMetricSpec[] =
        SettingsStore.$('prometheusMetrics').value

      metrics[index].name = value
      SettingsStore.$('prometheusMetrics').set(metrics)
    })
  }

  const handleChangeMetric = (index: number, value: string) => {
    runInAction(() => {
      const metrics: GeneralPrometheusConfigMetricSpec[] =
        SettingsStore.$('prometheusMetrics').value

      metrics[index].metric = value
      SettingsStore.$('prometheusMetrics').set(metrics)
    })
  }

  const handleChangeThreshold = (index: number, value: string) => {
    const validate = (value: string) => {
      if (value === '') {
        return true
      }

      const pattern = /^[-+]?(\d+(\.\d*)?|\.\d+)([eE][-+]?\d+)?$/
      return pattern.test(value)
    }

    runInAction(() => {
      if (!validate(value)) {
        return
      }

      const metrics: GeneralPrometheusConfigMetricSpec[] =
        SettingsStore.$('prometheusMetrics').value

      metrics[index].threshold = value
      SettingsStore.$('prometheusMetrics').set(metrics)
    })
  }

  const handleChangeUnit = (index: number, value: string) => {
    runInAction(() => {
      const metrics: GeneralPrometheusConfigMetricSpec[] =
        SettingsStore.$('prometheusMetrics').value

      metrics[index].unit = value
      SettingsStore.$('prometheusMetrics').set(metrics)
    })
  }

  const handleRemoveMetric = (index: number) => {
    runInAction(() => {
      const metrics: GeneralPrometheusConfigMetricSpec[] =
        SettingsStore.$('prometheusMetrics').value

      metrics.splice(index, 1)

      if (metrics.length === 0) {
        props.setSelectedRadioButton(0)
      }

      SettingsStore.$('prometheusMetrics').reset()
      SettingsStore.$('prometheusMetrics').set(metrics)
    })
  }

  const wrapperSetSelectedRadioButton = (data: string) => {
    props.setSelectedRadioButton(Number(data))
  }

  return (
    <UserInteractionPrometheusMetricInput
      key={`prometheus-config-metric-${props.index}`}
      helperText={`Заполните параметры метрики. Запрос составляется в формате PromQL, результатом должно быть число.`}
      index={props.index}
      environments={props.environments}
      selectedEnvironment={props.metric.environment}
      second={props.metric.name}
      third={props.metric.metric}
      fourth={props.metric.threshold}
      fift={props.metric.unit}
      secondLabel="Название"
      thirdLabel="Метрика"
      fourthLabel="Порог"
      fiftLabel="Единица"
      error={error}
      handleChangeEnvironment={(e) => {
        handleChangeEnvironment(props.index, e.toString())
      }}
      handleChangeSecond={(e) => {
        handleChangeName(props.index, e.target.value)
      }}
      handleChangeThird={(e) => {
        handleChangeMetric(props.index, e.target.value)
      }}
      handleChangeFourth={(e) => {
        handleChangeThreshold(props.index, e.target.value)
      }}
      handleChangeFift={(e) => {
        handleChangeUnit(props.index, e.target.value)
      }}
      radioButton={true}
      radioButtonTooltipText={'Метрика для отслеживания состояния'}
      selectedRadioButton={String(props.selectedRadioButton)}
      setSelectedRadioButton={wrapperSetSelectedRadioButton}
      removeButton
      handleRemove={() => {
        handleRemoveMetric(props.index)
      }}
    />
  )
})

const Prometheus = observer((props: PrometheusProps) => {
  const [selectedRadioButton, setSelectedRadioButton] = useState<number>(-1)

  const handleSelectedRadioButton = (index: number) => {
    runInAction(() => {
      const metrics: GeneralPrometheusConfigMetricSpec[] =
        SettingsStore.$('prometheusMetrics').value

      // Убираем прошлый index
      if (
        metrics.length > (selectedRadioButton >= 0 ? selectedRadioButton : 0)
      ) {
        metrics[
          selectedRadioButton >= 0 ? selectedRadioButton : 0
        ].healthcheck = false
      }

      if (metrics.length > 0) {
        metrics[index].healthcheck = true
      }

      SettingsStore.$('prometheusMetrics').set(metrics)

      setSelectedRadioButton(index)
    })
  }

  const handleAddMetric = () => {
    const metrics: GeneralPrometheusConfigMetricSpec[] =
      SettingsStore.$('prometheusMetrics').value

    if (metrics.length !== 0) {
      if (
        metrics[metrics.length - 1].name !== '' ||
        metrics[metrics.length - 1].metric !== ''
      ) {
        const config: GeneralPrometheusConfigMetricSpec[] =
          SettingsStore.$('prometheusMetrics').value
        config.push({
          environment: '',
          name: '',
          metric: '',
          threshold: '',
          unit: '',
          healthcheck: false,
        })
        SettingsStore.$('prometheusMetrics').reset()
        SettingsStore.$('prometheusMetrics').set(config)
      }
    } else {
      const config: GeneralPrometheusConfigMetricSpec[] =
        SettingsStore.$('prometheusMetrics').value
      config.push({
        environment: '',
        name: '',
        metric: '',
        threshold: '',
        unit: '',
        healthcheck: true,
      })
      SettingsStore.$('prometheusMetrics').reset()
      SettingsStore.$('prometheusMetrics').set(config)
      setSelectedRadioButton(0)
    }
  }

  useEffect(() => {
    const updateSelectedRadioButton = () => {
      const metrics: GeneralPrometheusConfigMetricSpec[] =
        SettingsStore.$('prometheusMetrics').value

      for (let i = 0; i < metrics.length; i++) {
        if (metrics[i].healthcheck) {
          setSelectedRadioButton(i)
          break
        }
      }
    }

    updateSelectedRadioButton()
  }, [props.loadingMetrics])

  return (
    <>
      <Typography
        variant="body1"
        paddingTop="30px"
        className="Typography-Body1"
      >
        Prometheus
      </Typography>
      <Grid
        sx={{
          marginBottom: '10px',
        }}
      >
        <>
          {!props.loadingEnvironments && !props.loadingMetrics ? (
            <>
              {SettingsStore.$('prometheusMetrics').value.length > 0 && (
                <>
                  {SettingsStore.$('prometheusMetrics').value.map(
                    (
                      metric: GeneralPrometheusConfigMetricSpec,
                      index: number
                    ) => {
                      return (
                        <PrometheusMetricItem
                          environments={props.environments}
                          metric={metric}
                          index={index}
                          selectedRadioButton={selectedRadioButton}
                          setSelectedRadioButton={handleSelectedRadioButton}
                        />
                      )
                    }
                  )}
                </>
              )}
              <div
                style={{
                  marginLeft: '5px',
                }}
              >
                <UserInteractionAddButton handleAdd={handleAddMetric} />
              </div>
            </>
          ) : (
            <>
              <CircularProgress size={20} />
            </>
          )}
        </>
      </Grid>
    </>
  )
})

export default Prometheus

import { React } from '@edp/core-common-frontend/dist/packages/react'
import {
  Grid,
  Typography,
} from '@edp/core-common-frontend/dist/packages/mui/material'

export const RatingCard = ({
  leftSlot,
  rightSlot,
  title,
  titleIcon,
}: {
  leftSlot: React.ReactNode
  rightSlot: React.ReactNode
  title: string
  titleIcon?: React.ReactNode
}) => {
  return (
    <Grid
      item
      sx={{
        margin: 0,
        width: '190px',
      }}
    >
      <Grid
        item
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Grid item sx={{ display: 'flex' }}>
          {leftSlot}
        </Grid>
        <Grid item sx={{ display: 'flex', marginLeft: 0.5 }}>
          {rightSlot}
        </Grid>
      </Grid>
      <Grid item sx={{ textAlign: 'center' }}>
        <Typography
          variant="body1"
          sx={{ display: 'inline-flex', verticalAlign: 'baseline' }}
        >
          {titleIcon} {title}
        </Typography>
      </Grid>
    </Grid>
  )
}

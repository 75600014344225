import { IconButton } from '@mui/material'
import { useEffect, useState } from 'react'
import { ApiClient } from 'api/ApiClient'
import { CustomSnackbarComponent } from '@edp/core-common-frontend'
import {
  ArgoCDInfo,
  DefectDojoInfo,
  GitlabInfo,
  GrafanaInfo,
  HarborInfo,
  INFRA_SERVICE_ARGOCD,
  INFRA_SERVICE_DEFECTDOJO,
  INFRA_SERVICE_GITLAB,
  INFRA_SERVICE_GRAFANA,
  INFRA_SERVICE_HARBOR,
  INFRA_SERVICE_OPENSEARCH,
  INFRA_SERVICE_OPENSEARCH_DASHBOARDS,
  INFRA_SERVICE_PROMETHEUS,
  INFRA_SERVICE_SONARQUBE,
  INFRA_SERVICE_VAULT,
  OpensearchInfo,
  OpensearchDashboardsInfo,
  PrometheusInfo,
  SonarqubeInfo,
  VaultInfo,
  INFRA_SERVICE_NEXUS,
  NexusStatus,
  INFRA_SERVICE_CUSTOM,
  INFRA_SERVICE_MATTERMOST,
} from 'types/Managers/InfraServices'
import UserInteractionInfraServiceTypeSelect from 'components/UserInteraction/UserInteractionInfraServiceTypeSelect'
import { CustomButton } from '@edp/core-common-frontend'
import UserInteractionCustom from 'components/UserInteraction/UserInteractionCustom'
import UserInteractionTextField from 'components/UserInteraction/UserInteractionTextField'
import { TbPlugConnected } from '@edp/core-common-frontend/dist/packages/react-icons/tb'
import { InfraServiceCheckConfiguration } from 'types/other'
import { HarborInfoCard } from '../cards/HarborInfoCard'
import { InfraServiceConfigurationSpec } from 'types/entities'
import { GitlabInfoCard } from '../cards/GitlabInfoCard'
import { GrafanaInfoCard } from '../cards/GrafanaInfoCard'
import { ArgocdInfoCard } from '../cards/ArgocdInfoCard'
import { DefectDojoInfoCard } from '../cards/DefectDojoInfoCard'
import { OpensearchInfoCard } from '../cards/OpensearchInfoCard'
import { OpensearchDashboardsInfoCard } from '../cards/OpensearchDashboardInfoCard'
import { PrometheusInfoCard } from '../cards/PrometheusInfoCard'
import { SonarqubeInfoCard } from '../cards/SonarqubeInfoCard'
import { VaultInfoCard } from '../cards/VaultInfoCard'
import UserInteractionPasswordTextField from 'components/UserInteraction/UserInteractionPasswordTextField'
import { NexusInfoCard } from '../cards/NexusInfoCard'
import UserInteractionClusterSelect from 'components/UserInteraction/UserInteractionClusterSelect'

export const AddInfraServicePanel = () => {
  const [name, setName] = useState<string>('default')
  const [description, setDescription] = useState<string>('')
  const [service, setService] = useState<string>('')
  const [url, setUrl] = useState<string>('')
  const [token, setToken] = useState<string>('')
  const [username, setUsername] = useState<string>('')
  const [cluster, setCluster] = useState<string>('')
  const [namespace, setNamespace] = useState<string>('')
  const [startPortRange, setStartPortRange] = useState<number>()
  const [endPortRange, setEndPortRange] = useState<number>()
  const [labelK8S, setLabelK8S] = useState<string>('')
  const [roleID, setRoleID] = useState<string>('')
  const [secretID, setSecretID] = useState<string>('')

  const [loading, setLoading] = useState<boolean>(false)
  const [created, setCreated] = useState<boolean>(false)
  const [failed, setFailed] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [connectionStatus, setConnectionStatus] = useState<string>('')
  const [connectionInfo, setConnectionInfo] = useState<object>({})

  const apiClient = new ApiClient()

  useEffect(() => {
    const clearState = async () => {
      setConnectionInfo({})
      setConnectionStatus('')
      setUrl('')
      setToken('')
      setUsername('')
    }

    clearState()
  }, [service])

  const checkConnection = async (): Promise<string> => {
    setConnectionStatus('loading')

    const config: InfraServiceCheckConfiguration = {
      type: service,
      url: url,
      token: token,
    }

    try {
      const data = await apiClient.checkConnectInfraService(config)
      setConnectionInfo(data)
      setConnectionStatus('success')
      return 'success'
    } catch (e) {
      setFailed(true)
      setErrorMessage(
        'Подключение к сервису не установлено. Проверьте корректность заполненных данных.'
      )
      setConnectionStatus('error')
      return 'error'
    } finally {
      console.log(connectionStatus)
    }
  }

  const create = async () => {
    try {
      if (name === '' || url === '' || token === '') {
        setFailed(true)
        setErrorMessage('Не все обязательные поля заполнены')
        return
      }

      /**
      if (service === INFRA_SERVICE_NEXUS) {

        if (
          namespace === '' ||
          labelK8S === '' ||
          startPortRange === undefined ||
          endPortRange === undefined
        ) {
          setFailed(true)
          setErrorMessage('Не все обязательные поля заполнены')
          return
        }

        if (endPortRange < startPortRange) {
          setFailed(true)
          setErrorMessage('Конечный порт не может быть меньше начального')
          return
        }
      }
      */

      setLoading(true)

      const config: InfraServiceConfigurationSpec = {
        name: name,
        description: description,
        type: service,
        url: url,
        token: token,
        username: username,
        namespace: namespace || undefined,
        startPortRange: startPortRange || undefined,
        endPortRange: endPortRange || undefined,
        labelK8S: labelK8S || undefined,
        roleID: roleID || undefined,
        secretID: secretID || undefined,
      }

      await apiClient.createInfraService(config)
      setLoading(false)
      setCreated(true)

      /*
      await checkConnection().then(async (res: string) => {
        if (res !== 'success') {
          return
        }

        setLoading(true)

        const config: InfraServiceConfigurationSpec = {
          name: name,
          description: description,
          type: service,
          url: url,
          token: token,
          username: username,
          namespace: namespace || undefined,
          startPortRange: startPortRange || undefined,
          endPortRange: endPortRange || undefined,
          labelK8S: labelK8S || undefined,
          roleID: roleID || undefined,
          secretID: secretID || undefined,
        }

        await apiClient.createInfraService(config)
        setLoading(false)
        setCreated(true)
      })
      */
    } catch (e) {
      setLoading(false)
      setFailed(true)
      if (e instanceof Error) {
        setErrorMessage(e.message)
      } else {
        setErrorMessage('unknown')
      }
    }
  }

  return (
    <>
      <UserInteractionInfraServiceTypeSelect
        name="service"
        description="Инфраструктурный сервис"
        helperText="Тип инфраструктурного сервиса."
        selected={service}
        setSelected={setService}
      />
      <UserInteractionTextField
        disabled={
          !(
            service == INFRA_SERVICE_CUSTOM ||
            service == INFRA_SERVICE_MATTERMOST ||
            service == INFRA_SERVICE_PROMETHEUS ||
            service == INFRA_SERVICE_SONARQUBE
          )
        }
        name="name"
        description="Название"
        helperText="Название инфраструктурного сервиса."
        value={name}
        onChange={(event) => setName(event.target.value)}
      />
      <UserInteractionTextField
        name="description"
        description="Описание"
        helperText="Описание инфраструктурного сервиса."
        value={description}
        onChange={(event) => setDescription(event.target.value)}
        multiline
        rows={6}
        height={'150px'}
      />
      <UserInteractionTextField
        name="url"
        description="URL"
        helperText="Адрес по которому доступен инфраструктурный сервис."
        value={url}
        onChange={(event) => setUrl(event.target.value)}
      />
      <UserInteractionPasswordTextField
        name="token"
        description="Токен"
        helperText="Токен для подключения к инфраструктурному сервису."
        value={token}
        onChange={(event) => setToken(event.target.value)}
      />
      <UserInteractionTextField
        name="username"
        description="Имя пользователя"
        helperText="Имя пользователя для подключения к инфраструктурному сервису."
        value={username}
        onChange={(event) => setUsername(event.target.value)}
      />
      {service === INFRA_SERVICE_ARGOCD || service === INFRA_SERVICE_NEXUS ? (
        <>
          <UserInteractionClusterSelect
            name="cluster"
            description="Кластер"
            helperText="Kubernetes кластер в котором развернут инфраструктурный сервис."
            selected={cluster}
            setSelected={setCluster}
          />
          <UserInteractionTextField
            name="namespace"
            description="Namespace"
            helperText="Namespace в Kubernetes в котором развернут инфраструктурный сервис."
            value={namespace}
            onChange={(event) => setNamespace(event.target.value)}
          />
        </>
      ) : (
        <></>
      )}
      {service === INFRA_SERVICE_NEXUS ? (
        <UserInteractionTextField
          name="startPortRange"
          description="Первый порт диапазона"
          helperText="Первый доступный порт из диапазона портов, выделенных инфраструктурному сервису"
          value={String(startPortRange || 0)}
          onChange={(event) => setStartPortRange(Number(event.target.value))}
        />
      ) : (
        <></>
      )}
      {service === INFRA_SERVICE_NEXUS ? (
        <UserInteractionTextField
          name="endPortRange"
          description="Последний порт диапазона"
          helperText="Последний доступный порт из диапазона портов, выделенных инфраструктурному сервису"
          value={String(endPortRange || 0)}
          onChange={(event) => setEndPortRange(Number(event.target.value))}
        />
      ) : (
        <></>
      )}
      {service === INFRA_SERVICE_NEXUS ? (
        <UserInteractionTextField
          name="labelK8S"
          description="Метка подов в Kubernetes"
          helperText="Метка (label) подов в Kubernetes, позволяющая однозначно определить поды относящиеся к данному инфраструктурному сервису"
          value={String(labelK8S)}
          onChange={(event) => setLabelK8S(event.target.value)}
        />
      ) : (
        <></>
      )}
      {service === INFRA_SERVICE_VAULT ? (
        <>
          <UserInteractionTextField
            name="role-id"
            description="AppRole RoleID"
            helperText="Идентификатор AppRole (RoleID)"
            value={roleID}
            onChange={(event) => setRoleID(event.target.value)}
          />
          <UserInteractionTextField
            name="secret-id"
            description="AppRole SecretID"
            helperText="Секрет AppRole (SecretID)"
            value={secretID}
            onChange={(event) => setSecretID(event.target.value)}
          />
        </>
      ) : (
        <></>
      )}
      <UserInteractionCustom
        name="check"
        description="Проверить подключение"
        helperText="Проверить подключение к инфраструктурному сервису."
      >
        <IconButton onClick={checkConnection} sx={{ marginLeft: '-6px' }}>
          <TbPlugConnected style={{ color: '#808080' }} />
        </IconButton>
      </UserInteractionCustom>
      <CustomButton
        sx={{
          height: '40px',
          width: 280,
          marginTop: '50px',
          marginBottom: '10px',
        }}
        loading={loading}
        onClick={create}
      >
        Зарегистрировать
      </CustomButton>
      {service === INFRA_SERVICE_HARBOR && connectionStatus === 'success' && (
        <HarborInfoCard
          status={connectionStatus}
          error={errorMessage}
          info={connectionInfo as HarborInfo}
        />
      )}
      {service === INFRA_SERVICE_GITLAB && connectionStatus === 'success' && (
        <GitlabInfoCard
          status={connectionStatus}
          error={errorMessage}
          info={connectionInfo as GitlabInfo}
        />
      )}
      {service === INFRA_SERVICE_GRAFANA && connectionStatus === 'success' && (
        <GrafanaInfoCard
          status={connectionStatus}
          error={errorMessage}
          info={connectionInfo as GrafanaInfo}
        />
      )}
      {service === INFRA_SERVICE_ARGOCD && connectionStatus === 'success' && (
        <ArgocdInfoCard
          status={connectionStatus}
          error={errorMessage}
          info={connectionInfo as ArgoCDInfo}
        />
      )}
      {service === INFRA_SERVICE_DEFECTDOJO &&
        connectionStatus === 'success' && (
          <DefectDojoInfoCard
            status={connectionStatus}
            error={errorMessage}
            info={connectionInfo as DefectDojoInfo}
          />
        )}
      {service === INFRA_SERVICE_OPENSEARCH &&
        connectionStatus === 'success' && (
          <OpensearchInfoCard
            status={connectionStatus}
            error={errorMessage}
            info={connectionInfo as OpensearchInfo}
          />
        )}
      {service === INFRA_SERVICE_OPENSEARCH_DASHBOARDS &&
        connectionStatus === 'success' && (
          <OpensearchDashboardsInfoCard
            status={connectionStatus}
            error={errorMessage}
            info={connectionInfo as OpensearchDashboardsInfo}
          />
        )}
      {service === INFRA_SERVICE_PROMETHEUS &&
        connectionStatus === 'success' && (
          <PrometheusInfoCard
            status={connectionStatus}
            error={errorMessage}
            info={connectionInfo as PrometheusInfo}
          />
        )}
      {/* {service === INFRA_SERVICE_SONARQUBE &&
        connectionStatus === 'success' && (
          <SonarqubeInfoCard
            status={connectionStatus}
            error={errorMessage}
            info={connectionInfo as SonarqubeInfo}
          />
        )} */}
      {service === INFRA_SERVICE_VAULT && connectionStatus === 'success' && (
        <VaultInfoCard
          status={connectionStatus}
          error={errorMessage}
          info={connectionInfo as VaultInfo}
        />
      )}
      {service === INFRA_SERVICE_NEXUS && connectionStatus === 'success' && (
        <NexusInfoCard
          status={connectionStatus}
          error={errorMessage}
          info={connectionInfo as NexusStatus}
        />
      )}
      <CustomSnackbarComponent
        opened={created}
        setOpened={setCreated}
        message="Инфраструктурный сервис зарегистрирован"
        severity="success"
      />
      <CustomSnackbarComponent
        opened={failed}
        setOpened={setFailed}
        message={`${errorMessage}`}
        severity="error"
      />
    </>
  )
}

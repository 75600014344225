import { Avatar, Tooltip, Typography } from '@mui/material'
import { GitlabContributorData } from '@edp/types'

type Props = {
  contributor: GitlabContributorData
}

export const Contributor = ({ contributor }: Props) => {
  const generateAvatarSymbols = (name: string): string => {
    if (name.length === 1) {
      return name.toLocaleUpperCase()
    }

    const tmp = name.split(' ')

    if (tmp.length > 1) {
      return `${tmp[0].substring(0, 1).toLocaleUpperCase()}${tmp[1]
        .substring(0, 1)
        .toLocaleUpperCase()}`
    } else {
      return `${tmp[0].substring(0, 2).toLocaleUpperCase()}`
    }
  }

  return (
    <>
      {contributor.avatar_url ? (
        <>
          <Tooltip
            style={{
              backgroundColor: 'white',
              border: '1px solid lightgrey',
              color: '#333',
            }}
            title={contributor.name}
          >
            <Avatar
              key={contributor.name}
              alt={contributor.name}
              src={contributor.avatar_url}
            />
          </Tooltip>
        </>
      ) : (
        <>
          <Tooltip title={contributor.name}>
            <Avatar key={contributor.name} alt={contributor.name}>
              <Typography>{generateAvatarSymbols(contributor.name)}</Typography>
            </Avatar>
          </Tooltip>
        </>
      )}
    </>
  )
}

import React, { useEffect, useState } from 'react'
import { CircularProgress, Grid, Typography } from '@mui/material'
import {
  KubernetesNamespaceSpec,
  ServiceKubernetesConfigObjectsType,
  TeamPresettingsConfigHelmReleaseSpec,
  TeamPresettingsConfigKubernetesObjectsSpec,
  TeamSpec,
} from '@edp/types'
import { getKubernetesNamespaces } from '@edp/core-common-frontend'
import UserInteractionCustomMultipleSelect from 'components/UserInteraction/UserInteractionCustomMultipleSelect'
import UserInteractionSwitch from 'components/UserInteraction/UserInteractionSwitch'
import UserInteractionTextField from 'components/UserInteraction/UserInteractionTextField'
import { UserInteractionAddButton } from 'components/UserInteraction/UserInteractionAddButton'

interface PresettingsServiceHelmProps {
  team: TeamSpec
  setTeam: React.Dispatch<React.SetStateAction<TeamSpec>>
}

interface PresettingsServiceKubernetesProps {
  team: TeamSpec
  setTeam: React.Dispatch<React.SetStateAction<TeamSpec>>
}

export interface PresettingsServiceProps {
  team: TeamSpec
  setTeam: React.Dispatch<React.SetStateAction<TeamSpec>>
}

const PresettingsServiceHelm = (props: PresettingsServiceHelmProps) => {
  const handleChangeHelmRelease = (index: number, value: string) => {
    const team = { ...props.team }

    if (
      team.presettingsConfigRef &&
      team.presettingsConfigRef.helmReleases &&
      team.presettingsConfigRef.helmReleases.length > 0
    ) {
      team.presettingsConfigRef.helmReleases[index].name = value
      props.setTeam(team)
    }
  }

  const handleAddHelmRelease = () => {
    const team = { ...props.team }

    if (team.presettingsConfigRef) {
      if (
        team.presettingsConfigRef.helmReleases &&
        team.presettingsConfigRef.helmReleases.length > 0
      ) {
        if (
          team.presettingsConfigRef.helmReleases[
            team.presettingsConfigRef.helmReleases.length - 1
          ].name !== ''
        ) {
          team.presettingsConfigRef.helmReleases.push({
            team: '',
            name: '',
          })
        }
      } else {
        team.presettingsConfigRef.helmReleases = [{ team: '', name: '' }]
      }
    }

    props.setTeam(team)
  }

  const handleRemoveHelmRelease = (index: number) => {
    const team = { ...props.team }

    if (
      team.presettingsConfigRef?.helmReleases &&
      team.presettingsConfigRef.helmReleases.length > 0
    ) {
      team.presettingsConfigRef.helmReleases.splice(index, 1)
      props.setTeam(team)
    }
  }

  return (
    <>
      <Typography
        variant="body1"
        sx={{
          paddingTop: '30px',
          paddingBottom: '15px',
          fontWeight: '600',
        }}
      >
        Helm релизы
      </Typography>
      <Grid>
        {props.team.presettingsConfigRef?.helmReleases &&
          props.team.presettingsConfigRef.helmReleases.length > 0 &&
          props.team.presettingsConfigRef.helmReleases.map(
            (value: TeamPresettingsConfigHelmReleaseSpec, index: number) => {
              return (
                <UserInteractionTextField
                  name={'Название'}
                  description={'Название Helm release'}
                  helperText={'Название Helm release'}
                  value={value.name}
                  onChange={(e) => {
                    handleChangeHelmRelease(index, e.target.value)
                  }}
                  removeButton
                  handleRemove={() => {
                    handleRemoveHelmRelease(index)
                  }}
                />
              )
            }
          )}
        <UserInteractionAddButton handleAdd={handleAddHelmRelease} />
      </Grid>
    </>
  )
}

const PresettingsServiceKubernetes = (
  props: PresettingsServiceKubernetesProps
) => {
  const [selectedNamespaces, setSelectedNamespaces] = useState<Array<string>>(
    []
  )
  const [kubernetesNamespaces, setKubernetesNamespaces] = useState<
    Array<KubernetesNamespaceSpec>
  >([])
  const [formattedKubernetesNamespaces, setFormattedKubernetesNamespaces] =
    useState<Array<string>>([])
  const [loadingNamespaces, setLoadingNamespaces] = useState<boolean>(false)

  const [mapKubernetesObjects, setMapKubernetesObjects] = useState<
    Map<string, TeamPresettingsConfigKubernetesObjectsSpec>
  >(new Map())
  const [
    stateKubernetesObjectsAllowDeletion,
    setStateKubernetesObjectsAllowDeletion,
  ] = useState<Record<string, any>>({})

  const getNamespaceByName = (
    name: string
  ): KubernetesNamespaceSpec | undefined => {
    try {
      for (const namespace of kubernetesNamespaces) {
        if (namespace.name === name) {
          if (namespace.uuid) {
            return namespace
          } else {
            return undefined
          }
        }
      }
    } catch (e) {
      console.log(`При определении UUID namespace произошла ошибка: ${e}`)
    }

    return undefined
  }

  const handleChangeCheckedAllowDelete = (
    objName: string,
    checked: boolean
  ) => {
    const objConfig = mapKubernetesObjects.get(objName)
    if (objConfig) {
      objConfig.allowDeletion = checked
      mapKubernetesObjects.set(objName, { ...objConfig })
      setStateKubernetesObjectsAllowDeletion({
        ...stateKubernetesObjectsAllowDeletion,
        [`allowDelete-${objConfig.object}`]: objConfig.allowDeletion,
      })
    }
  }

  useEffect(() => {
    const getNamespaces = async () => {
      setLoadingNamespaces(true)
      try {
        const data = await getKubernetesNamespaces()
        setKubernetesNamespaces(data)

        // Получаем только имена
        const kubernetesNamespaceNames: string[] = []
        for (const namespace of data) {
          kubernetesNamespaceNames.push(namespace.name)
        }

        setFormattedKubernetesNamespaces(kubernetesNamespaceNames)
      } catch (e) {
        console.error('Ошибка при получении данных: ', e)
      } finally {
        setLoadingNamespaces(false)
      }
    }

    getNamespaces()
  }, [])

  useEffect(() => {
    const objectsConfigMap: Map<
      string,
      TeamPresettingsConfigKubernetesObjectsSpec
    > = new Map()

    // Преобразуем
    if (
      props.team.presettingsConfigRef &&
      props.team.presettingsConfigRef.kubernetesConfigRef
    ) {
      for (const obj of props.team.presettingsConfigRef.kubernetesConfigRef
        .objects) {
        objectsConfigMap.set(obj.object, obj)
      }
    }

    // Проверяем и добавляем недостающие объекты
    for (const objName of Object.values(ServiceKubernetesConfigObjectsType)) {
      if (!objectsConfigMap.has(objName)) {
        const defaultConfigObj: TeamPresettingsConfigKubernetesObjectsSpec = {
          team: '',
          object: objName,
          allowDeletion: false,
        }
        objectsConfigMap.set(objName, { ...defaultConfigObj })
      }
    }

    // Устанавливаем стейт на удаление
    for (const [key, value] of objectsConfigMap.entries()) {
      setStateKubernetesObjectsAllowDeletion({
        ...stateKubernetesObjectsAllowDeletion,
        [`allowDelete-${key}`]: value.allowDeletion,
      })
    }

    // Обновляем Team
    if (
      props.team.presettingsConfigRef &&
      props.team.presettingsConfigRef.kubernetesConfigRef
    ) {
      props.team.presettingsConfigRef.kubernetesConfigRef.objects = Array.from(
        mapKubernetesObjects,
        ([_, value]) => value
      )
      props.setTeam(props.team)
    }

    setMapKubernetesObjects(objectsConfigMap)
  }, [props.team.name])

  useEffect(() => {
    if (kubernetesNamespaces.length !== 0 && props.team.name !== '') {
      // Получаем выбранные имена
      const selectedKubernetesNamespaceNames: Array<string> = []
      if (
        props.team.presettingsConfigRef &&
        props.team.presettingsConfigRef.kubernetesConfigRef &&
        props.team.presettingsConfigRef.kubernetesConfigRef.namespaces
      ) {
        for (const namespace of props.team.presettingsConfigRef
          .kubernetesConfigRef.namespaces) {
          selectedKubernetesNamespaceNames.push(namespace.name)
        }

        setSelectedNamespaces(selectedKubernetesNamespaceNames)
      }
    }
  }, [kubernetesNamespaces, formattedKubernetesNamespaces, props.team.name])

  useEffect(() => {
    const tmp: KubernetesNamespaceSpec[] = []

    for (const namespace of selectedNamespaces) {
      const result = getNamespaceByName(namespace)

      if (result !== undefined) {
        tmp.push(result)
      }
    }

    if (
      props.team.presettingsConfigRef &&
      props.team.presettingsConfigRef.kubernetesConfigRef
    ) {
      props.team.presettingsConfigRef.kubernetesConfigRef.namespaces = tmp
      props.setTeam(props.team)
    }
  }, [selectedNamespaces])

  useEffect(() => {
    if (
      props.team.presettingsConfigRef &&
      props.team.presettingsConfigRef.kubernetesConfigRef
    ) {
      props.team.presettingsConfigRef.kubernetesConfigRef.objects = Array.from(
        mapKubernetesObjects,
        ([_, value]) => value
      )
    }
  }, [stateKubernetesObjectsAllowDeletion])

  return (
    <>
      <Typography
        variant="body1"
        sx={{
          paddingTop: '30px',
          paddingBottom: '15px',
          fontWeight: '600',
        }}
      >
        Kubernetes
      </Typography>
      <Grid>
        {loadingNamespaces ? (
          <CircularProgress size={20} />
        ) : (
          <>
            <UserInteractionCustomMultipleSelect
              name="namespaces"
              description="Namespaces"
              helperText="Выберите namespace, в которых может быть развернут сервис"
              options={formattedKubernetesNamespaces}
              selected={selectedNamespaces}
              setSelected={setSelectedNamespaces}
            />
            <UserInteractionSwitch
              name={`allowDelete-${ServiceKubernetesConfigObjectsType.Pod}`}
              helperText={`Включить возможность удаления подов`}
              checked={
                stateKubernetesObjectsAllowDeletion[
                  `allowDelete-${ServiceKubernetesConfigObjectsType.Pod}`
                ] || false
              }
              description="Возможность удаления подов"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                handleChangeCheckedAllowDelete(
                  ServiceKubernetesConfigObjectsType.Pod,
                  event.target.checked
                )
              }}
            />
          </>
        )}
      </Grid>
    </>
  )
}

export const PresettingsService = (props: PresettingsServiceProps) => {
  return (
    <>
      <Typography
        variant="body1"
        paddingTop="30px"
        className="Typography-Body1"
      >
        Настройки создания сервиса по умолчанию
      </Typography>
      <PresettingsServiceKubernetes team={props.team} setTeam={props.setTeam} />
      <PresettingsServiceHelm team={props.team} setTeam={props.setTeam} />
    </>
  )
}

export default PresettingsService

import {
  InfraServiceSpec,
  ServiceSonarqubeConfigInstanceSpec,
  ServiceSonarqubeConfigSpec,
  TeamSonarqubeConfigInstanceSpec,
  TeamSonarqubeConfigSpec,
} from '@edp/types'
import { CircularProgress, Divider, Grid, Typography } from '@mui/material'
import { ApiClient } from 'api/ApiClient'
import { UserInteractionAddButton } from 'components/UserInteraction/UserInteractionAddButton'
import UserInteractionSonarqubeConfigInput from 'components/UserInteraction/UserInteractionSonarqubeConfigInput'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import ServiceStore from './store'
import { runInAction } from 'mobx'
import { INFRA_SERVICE_SONARQUBE } from 'types/Managers/InfraServices'

interface SonarQubeInstanceProps {
  index: number
  selectedSonarqubeInstanceInitID: number
  project: string
  sonarqubeInfraServices?: InfraServiceSpec[]
  removeButton?: boolean
  disabled?: boolean
}

export interface SonarQubeProps {}

export const SonarQubeInstance = observer((props: SonarQubeInstanceProps) => {
  const [selectedSonarqubeInstance, setSelectedSonarqubeInstance] =
    useState<number>(-1)

  useEffect(() => {
    setSelectedSonarqubeInstance(props.selectedSonarqubeInstanceInitID)
  }, [props.selectedSonarqubeInstanceInitID])

  const handleChangeSonarqubeInstance = (index: number, value: string) => {
    runInAction(() => {
      const config: ServiceSonarqubeConfigSpec = {
        ...ServiceStore.$('sonarqubeConfigRef').value,
      }

      if (config.instances && config.instances.length > index) {
        config.instances[index].infraService = Number(value)
        setSelectedSonarqubeInstance(Number(value))
        ServiceStore.$(`sonarqubeConfigRef`).set({ ...config })
      }
    })
  }

  const handleChangeProject = (index: number, value: string) => {
    runInAction(() => {
      const config: ServiceSonarqubeConfigSpec = {
        ...ServiceStore.$('sonarqubeConfigRef').value,
      }
      if (config.instances && config.instances.length > index) {
        config.instances[index].project = value
        ServiceStore.$(`sonarqubeConfigRef`).set({ ...config })
      }
    })
  }

  const handleRemoveInstance = (index: number) => {
    runInAction(() => {
      const config: ServiceSonarqubeConfigSpec = {
        ...ServiceStore.$('sonarqubeConfigRef').value,
      }
      config.instances.splice(index, 1)
      ServiceStore.$(`sonarqubeConfigRef`).reset()
      ServiceStore.$(`sonarqubeConfigRef`).set({ ...config })
    })
  }

  return (
    <>
      <UserInteractionSonarqubeConfigInput
        key={`sonarqube-config-metric-${props.index}`}
        index={props.index}
        selectedSonarqubeInstance={String(selectedSonarqubeInstance)}
        project={props.project}
        handleChangeSonarqubeInstance={(e) => {
          handleChangeSonarqubeInstance(props.index, e.toString())
        }}
        handleChangeProject={(e) => {
          handleChangeProject(props.index, e.target.value)
        }}
        projectDisable={props.disabled}
        removeButton={props.removeButton}
        handleRemove={() => {
          handleRemoveInstance(props.index)
        }}
        disabled={props.disabled}
        sonarqubeInfraServices={props.sonarqubeInfraServices}
      />
    </>
  )
})

export const SonarQube = observer((props: SonarQubeProps) => {
  const [teamSonarqubeConfigurations, setTeamSonarqubeConfigurations] =
    useState<TeamSonarqubeConfigSpec>()
  const [
    loadingTeamSonarqubeConfigurations,
    setLoadingTeamSonarqubeConfigurations,
  ] = useState<boolean>(false)

  const [sonarqubeInfraServices, setSonarqubeInfraServices] = useState<
    InfraServiceSpec[]
  >([])
  const [loadingSonarqubeInfraServices, setLoadingSonarqubeInfraServices] =
    useState<boolean>(false)

  useEffect(() => {
    const apiClient = new ApiClient()

    const getSonarqubeInfraServices = async () => {
      try {
        setLoadingSonarqubeInfraServices(true)
        const data = await apiClient.getInfraServicesByType(
          INFRA_SERVICE_SONARQUBE
        )
        setSonarqubeInfraServices(data)
      } catch (e) {
        console.log(e)
      } finally {
        setLoadingSonarqubeInfraServices(false)
      }
    }

    getSonarqubeInfraServices()
  }, [])

  useEffect(() => {
    const apiClient = new ApiClient()

    const getTeamSonarqubeConfigurations = async () => {
      try {
        setLoadingTeamSonarqubeConfigurations(true)
        const data = await apiClient.getTeamSonarqubeConfigurations(
          ServiceStore.$('owner').value
        )
        setTeamSonarqubeConfigurations(data)
      } catch (e) {
        console.log(e)
      } finally {
        setLoadingTeamSonarqubeConfigurations(false)
      }
    }

    getTeamSonarqubeConfigurations()
  }, [ServiceStore.$('owner').value])

  const handleAddInstance = () => {
    const instances: ServiceSonarqubeConfigInstanceSpec[] = ServiceStore.$(
      'sonarqubeConfigRef.instances'
    ).value

    if (instances.length != 0) {
      if (
        instances[instances.length - 1].infraService !== -1 ||
        instances[instances.length - 1].project !== ''
      ) {
        const config: ServiceSonarqubeConfigSpec = {
          ...ServiceStore.$('sonarqubeConfigRef').value,
        }
        config.instances.push({
          service: '',
          infraService: -1,
          project: '',
        })
        ServiceStore.$('sonarqubeConfigRef').reset()
        ServiceStore.$('sonarqubeConfigRef').set({ ...config })
      }
    } else {
      const config: ServiceSonarqubeConfigSpec = {
        ...ServiceStore.$('sonarqubeConfigRef').value,
      }
      config.instances = [
        {
          service: '',
          infraService: -1,
          project: '',
        },
      ]
      ServiceStore.$('sonarqubeConfigRef').reset()
      ServiceStore.$('sonarqubeConfigRef').set({ ...config })
    }
  }

  return (
    <>
      <Typography
        variant="body1"
        paddingTop="30px"
        className="Typography-Body1"
      >
        SonarQube
      </Typography>
      <Grid
        sx={{
          marginBottom: '10px',
        }}
      >
        {!loadingSonarqubeInfraServices &&
        !loadingTeamSonarqubeConfigurations ? (
          <>
            {teamSonarqubeConfigurations &&
              teamSonarqubeConfigurations.instances.length > 0 && (
                <Grid
                  sx={{
                    marginBottom: '10px',
                  }}
                >
                  <Grid
                    sx={{
                      width: '800px',
                    }}
                  >
                    <Divider>Настройки команды</Divider>
                  </Grid>
                  {teamSonarqubeConfigurations.instances.map(
                    (
                      instance: TeamSonarqubeConfigInstanceSpec,
                      index: number
                    ) => {
                      return (
                        <SonarQubeInstance
                          index={index}
                          selectedSonarqubeInstanceInitID={
                            instance.infraService
                          }
                          project={instance.project}
                          disabled={true}
                          sonarqubeInfraServices={sonarqubeInfraServices}
                        />
                      )
                    }
                  )}
                </Grid>
              )}
            <Grid
              sx={{
                width: '800px',
              }}
            >
              <Divider>Настройки сервиса</Divider>
            </Grid>
            {ServiceStore.$('sonarqubeConfigRef.instances').value && (
              <>
                {ServiceStore.$('sonarqubeConfigRef.instances').value.length >
                  0 &&
                  ServiceStore.$('sonarqubeConfigRef.instances').value.map(
                    (
                      instance: ServiceSonarqubeConfigInstanceSpec,
                      index: number
                    ) => {
                      return (
                        <SonarQubeInstance
                          index={index}
                          selectedSonarqubeInstanceInitID={
                            instance.infraService
                          }
                          project={instance.project}
                          removeButton
                        />
                      )
                    }
                  )}
              </>
            )}
            <UserInteractionAddButton handleAdd={handleAddInstance} />
          </>
        ) : (
          <>
            <CircularProgress size={20} />
          </>
        )}
      </Grid>
    </>
  )
})

export default SonarQube

import {
  Box,
  CircularProgress,
  circularProgressClasses,
  CircularProgressProps,
  Grid,
  LinearProgress,
  linearProgressClasses,
  styled,
  Tooltip,
  Typography,
} from '@edp/core-common-frontend/dist/packages/mui/material'
import { HelpOutline } from '@edp/core-common-frontend/dist/packages/mui/icons-material'
import { ServiceSpec } from '@edp/types'
import { CSSProperties, useEffect, useState } from 'react'
import {
  countingFillingServiceFieldsStatus,
  StatusFillingServiceSpec,
} from 'utils/countingFillingServiceFields'

const BorderLinearProgress = styled(LinearProgress)(({ theme, value }) => ({
  height: 5,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#b0bec5',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: Number(value) < 50 ? '#f44336' : '#4caf50',
  },
}))

const CircularLinearProgress = styled(CircularProgress)(({ theme, value }) => ({
  position: 'absolute',
  [`&.${circularProgressClasses.colorPrimary}`]: {
    color: Number(value) < 50 ? '#f44336' : '#4caf50',
  },
  [`&.${circularProgressClasses.root}`]: {
    transform: 'rotate(0.35turn) !important',
  },
}))

const CircularLinearProgressBackground = styled(CircularProgress)(
  ({ theme, value }) => ({
    [`&.${circularProgressClasses.colorPrimary}`]: {
      color: '#b0bec5',
    },
    [`&.${circularProgressClasses.root}`]: {
      transform: 'rotate(0.35turn) !important',
    },
  })
)

export interface FillingStatusComponentProps {
  service: ServiceSpec
  type: FillingStatusComponentType
  enabledLoading: boolean
  styleContainer?: CSSProperties
  status?: StatusFillingServiceSpec
  size: string
}

export enum FillingStatusComponentType {
  Circular = 'circular',
  Linear = 'linear',
}

const CircularProgressWithLabel = (
  props: CircularProgressProps & {
    filledFields: number
    allFields: number
    size: string
  }
) => {
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'inline-flex',
      }}
    >
      <CircularLinearProgressBackground
        variant="determinate"
        disableShrink
        size={props.size}
        thickness={7}
        value={80}
      />
      <CircularLinearProgress
        variant="determinate"
        size={props.size}
        value={(Number(props.filledFields) / Number(props.allFields)) * 80}
        thickness={7}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          sx={{ color: 'text.secondary' }}
        >{`${props.filledFields}/${props.allFields}`}</Typography>
      </Box>
    </Box>
  )
}

export const FillingStatusComponent = (props: FillingStatusComponentProps) => {
  const [status, setStatus] = useState<StatusFillingServiceSpec>()
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (props.status === undefined) {
      setLoading(true)

      countingFillingServiceFieldsStatus(props.service)
        .then((result) => {
          setStatus(result)
          setLoading(false)
        })
        .catch((e) => {
          console.log(e)
          setStatus({
            message: 'Ошибка получения данных',
            filledFields: [],
            allFields: 0,
          })
          setLoading(false)
        })
    } else {
      setStatus(props.status)
    }
  }, [props.service])

  return (
    <>
      {!loading ? (
        <>
          {status ? (
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="baseline"
              spacing={1}
              sx={{
                borderRadius: '8px',
              }}
            >
              <Grid item sm={12}>
                {Number(status.filledFields.length) !==
                  Number(status.allFields) || status.allFields === 0 ? (
                  <Tooltip
                    title={
                      status.allFields === 0
                        ? 'Ошибка получения данных'
                        : status?.message
                    }
                    placement="bottom"
                    classes={{
                      popper: 'tooltipPopperServiceClass',
                      tooltipPlacementBottom: 'tooltipPopperServiceClassInfo',
                    }}
                    style={props.styleContainer}
                  >
                    <div>
                      {props.type === FillingStatusComponentType.Linear && (
                        <>
                          <Typography fontSize="0.8em">
                            <div
                              style={{
                                display: 'flex',
                              }}
                            >
                              {`Заполнено ${status.filledFields.length}/${status.allFields}`}
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  marginLeft: 'auto',
                                  marginBottom: '0.2em',
                                }}
                              >
                                <HelpOutline className="UserInteraction-HelpIcon"></HelpOutline>
                              </div>
                            </div>
                          </Typography>
                          <BorderLinearProgress
                            sx={{
                              width: '100%',
                            }}
                            variant="determinate"
                            value={
                              (Number(status.filledFields.length) /
                                Number(status.allFields)) *
                              100
                            }
                          />
                        </>
                      )}
                      {props.type === FillingStatusComponentType.Circular && (
                        <CircularProgressWithLabel
                          value={
                            (Number(status.filledFields.length) /
                              Number(status.allFields)) *
                            100
                          }
                          filledFields={status.filledFields.length}
                          allFields={status.allFields}
                          size={props.size}
                        />
                      )}
                    </div>
                  </Tooltip>
                ) : (
                  <div style={props.styleContainer}>
                    {props.type === FillingStatusComponentType.Linear && (
                      <>
                        <Typography fontSize="0.8em">
                          {`Заполнено ${status.filledFields.length}/${status.allFields}`}
                        </Typography>
                        <BorderLinearProgress
                          sx={{
                            width: '100%',
                          }}
                          variant="determinate"
                          value={
                            (Number(status.filledFields.length) /
                              Number(status.allFields)) *
                            100
                          }
                        />
                      </>
                    )}
                    {props.type === FillingStatusComponentType.Circular && (
                      <CircularProgressWithLabel
                        value={
                          (Number(status.filledFields.length) /
                            Number(status.allFields)) *
                          100
                        }
                        filledFields={status.filledFields.length}
                        allFields={status.allFields}
                        size={props.size}
                      />
                    )}
                  </div>
                )}
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          {props.enabledLoading ? (
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="baseline"
              spacing={1}
              sx={{
                borderRadius: '8px',
              }}
            >
              <Grid item xs={12} style={props.styleContainer}>
                <CircularProgress size={20} />
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  )
}

export default FillingStatusComponent

import React, { useEffect, useState } from 'react'

import { observer } from 'mobx-react-lite'
import { CircularProgress, Divider, Grid, Typography } from '@mui/material'
import ServiceStore from './store'
import { getKubernetesNamespaces } from '@edp/core-common-frontend'
import {
  KubernetesNamespaceSpec,
  ServiceKubernetesConfigObjectsSpec,
  ServiceKubernetesConfigObjectsType,
  ServiceKubernetesConfigSpec,
  TeamPresettingsConfigSpec,
} from '@edp/types'
import UserInteractionCustomMultipleSelect from 'components/UserInteraction/UserInteractionCustomMultipleSelect'
import UserInteractionSwitch from 'components/UserInteraction/UserInteractionSwitch'
import { EntityAction } from 'types/entities'

type KubernetesProps = {
  action: EntityAction
  disabled: boolean
  teamPresettings?: TeamPresettingsConfigSpec
  loadingTeamPresettings: boolean
}

const Kubernetes = observer((props: KubernetesProps) => {
  type settingsType = 'team' | 'service'

  // Team presettings
  const [selectedNamespacesTeamSettings, setSelectedNamespacesTeamSettings] =
    useState<Array<string>>([])

  const [
    mapKubernetesObjectsTeamSettings,
    setMapKubernetesObjectsTeamSettings,
  ] = useState<Map<string, ServiceKubernetesConfigObjectsSpec>>(new Map())

  const [
    stateKubernetesObjectsAllowDeletionTeamSettings,
    setStateKubernetesObjectsAllowDeletionTeamSettings,
  ] = useState<Record<string, any>>({})

  // Service settings
  const [selectedNamespaces, setSelectedNamespaces] = useState<Array<string>>(
    []
  )
  const [kubernetesNamespaces, setKubernetesNamespaces] = useState<
    Array<KubernetesNamespaceSpec>
  >([])
  const [formattedKubernetesNamespaces, setFormattedKubernetesNamespaces] =
    useState<Array<string>>([])
  const [
    formattedKubernetesNamespacesAvaliableService,
    setFormattedKubernetesNamespacesAvaliableService,
  ] = useState<Array<string>>([])
  const [loadingNamespaces, setLoadingNamespaces] = useState<boolean>(true)

  const [mapKubernetesObjects, setMapKubernetesObjects] = useState<
    Map<string, ServiceKubernetesConfigObjectsSpec>
  >(new Map())
  const [
    stateKubernetesObjectsAllowDeletion,
    setStateKubernetesObjectsAllowDeletion,
  ] = useState<Record<string, any>>({})

  const getNamespaceByName = (
    name: string
  ): KubernetesNamespaceSpec | undefined => {
    try {
      for (const namespace of kubernetesNamespaces) {
        if (namespace.name === name) {
          if (namespace.uuid) {
            return namespace
          } else {
            return undefined
          }
        }
      }
    } catch (e) {
      console.log(`При определении UUID namespace произошла ошибка: ${e}`)
    }

    return undefined
  }

  const handleChangeCheckedAllowDelete = (
    objName: string,
    checked: boolean,
    typeSettings: settingsType
  ) => {
    let objConfig = undefined

    if (typeSettings === 'team') {
      objConfig = mapKubernetesObjectsTeamSettings.get(objName)
    } else if (typeSettings === 'service') {
      objConfig = mapKubernetesObjects.get(objName)
    }

    if (objConfig) {
      objConfig.allowDeletion = checked

      if (typeSettings === 'team') {
        mapKubernetesObjectsTeamSettings.set(objName, { ...objConfig })
        setStateKubernetesObjectsAllowDeletionTeamSettings({
          ...stateKubernetesObjectsAllowDeletionTeamSettings,
          [`allowDelete-${objConfig.object}`]: objConfig.allowDeletion,
        })
      } else if (typeSettings === 'service') {
        mapKubernetesObjects.set(objName, { ...objConfig })
        setStateKubernetesObjectsAllowDeletion({
          ...stateKubernetesObjectsAllowDeletion,
          [`allowDelete-${objConfig.object}`]: objConfig.allowDeletion,
        })
      }
    }
  }

  useEffect(() => {
    const getNamespaces = async () => {
      setLoadingNamespaces(true)
      try {
        const data = await getKubernetesNamespaces()
        setKubernetesNamespaces(data)

        // Получаем только имена
        const kubernetesNamespaceNames: Array<string> = []
        for (const namespace of data) {
          kubernetesNamespaceNames.push(namespace.name)
        }

        setFormattedKubernetesNamespaces(kubernetesNamespaceNames)
      } catch (e) {
        console.error('Ошибка при получении данных: ', e)
      } finally {
        setLoadingNamespaces(false)
      }
    }

    getNamespaces()
  }, [])

  useEffect(() => {
    const kubernetesNamespaceNamesAvaliableService: Array<string> = []

    // Формируем список доступных сервису
    if (formattedKubernetesNamespaces.length > 0) {
      if (
        props.teamPresettings &&
        props.teamPresettings.kubernetesConfigRef &&
        props.teamPresettings.kubernetesConfigRef.namespaces.length > 0
      ) {
        const updateSelectedNamespaces = selectedNamespaces

        for (const namespace of formattedKubernetesNamespaces) {
          const exist: boolean =
            props.teamPresettings.kubernetesConfigRef.namespaces.some(
              (obj) => obj.name === namespace
            )

          // Если нет в с списке предустановленных, добавляем в список
          if (!exist) {
            kubernetesNamespaceNamesAvaliableService.push(namespace)
          } else {
            // Если нет, проверяем, выбран ли он уже
            const index = updateSelectedNamespaces.indexOf(namespace)
            if (index > -1) {
              // Если есть, то удаляем из списка выбранных
              updateSelectedNamespaces.splice(index, 1)
            }
          }
        }

        setFormattedKubernetesNamespacesAvaliableService(
          kubernetesNamespaceNamesAvaliableService
        )
        setSelectedNamespaces(updateSelectedNamespaces)
      } else {
        setFormattedKubernetesNamespacesAvaliableService(
          formattedKubernetesNamespaces
        )
      }
    }
  }, [props.teamPresettings, formattedKubernetesNamespaces])

  useEffect(() => {
    const objectsConfigMap: Map<string, ServiceKubernetesConfigObjectsSpec> =
      new Map()
    const config: ServiceKubernetesConfigSpec = {
      ...ServiceStore.$('kubernetesConfigRef').value,
    }

    // Преобразуем
    if (ServiceStore.$('kubernetesConfigRef.objects').value) {
      for (const obj of config.objects) {
        objectsConfigMap.set(obj.object, obj)
      }
    }

    // Проверяем и добавляем недостающие объекты
    for (const objName of Object.values(ServiceKubernetesConfigObjectsType)) {
      if (!objectsConfigMap.has(objName)) {
        const defaultConfigObj: ServiceKubernetesConfigObjectsSpec = {
          service: '',
          object: objName,
          allowDeletion: false,
        }
        objectsConfigMap.set(objName, { ...defaultConfigObj })
      }
    }

    // Устанавливаем стейт на удаление
    for (const [key, value] of objectsConfigMap.entries()) {
      setStateKubernetesObjectsAllowDeletion({
        ...stateKubernetesObjectsAllowDeletion,
        [`allowDelete-${key}`]: value.allowDeletion,
      })
    }

    // Обновляем Store
    config.objects = Array.from(mapKubernetesObjects, ([_, value]) => value)

    ServiceStore.$('kubernetesConfigRef').reset()
    ServiceStore.$('kubernetesConfigRef').set({ ...config })

    setMapKubernetesObjects(objectsConfigMap)
  }, [ServiceStore.$('name').value])

  useEffect(() => {
    // Namespaces
    const selectedKubernetesNamespaceNames: Array<string> = []

    if (props.teamPresettings && props.teamPresettings.kubernetesConfigRef) {
      if (props.teamPresettings.kubernetesConfigRef.namespaces.length > 0) {
        props.teamPresettings.kubernetesConfigRef.namespaces.forEach(
          (namespace) => {
            if (!selectedKubernetesNamespaceNames.includes(namespace.name)) {
              selectedKubernetesNamespaceNames.push(namespace.name)
            }
          }
        )
      }
    }

    setSelectedNamespacesTeamSettings(selectedKubernetesNamespaceNames)

    // Objects

    // Сохраняем информацию о командных настройках

    const objectsConfigMap: Map<string, ServiceKubernetesConfigObjectsSpec> =
      new Map()

    // Преобразуем
    if (
      props.teamPresettings &&
      props.teamPresettings.kubernetesConfigRef &&
      props.teamPresettings.kubernetesConfigRef.objects.length > 0
    ) {
      for (const obj of props.teamPresettings.kubernetesConfigRef.objects) {
        objectsConfigMap.set(obj.object, {
          service: '',
          object: obj.object,
          allowDeletion: obj.allowDeletion,
        })
      }
    }

    // Проверяем и добавляем недостающие объекты
    for (const objName of Object.values(ServiceKubernetesConfigObjectsType)) {
      if (!objectsConfigMap.has(objName)) {
        const defaultConfigObj: ServiceKubernetesConfigObjectsSpec = {
          service: '',
          object: objName,
          allowDeletion: false,
        }
        objectsConfigMap.set(objName, { ...defaultConfigObj })
      }
    }

    setMapKubernetesObjectsTeamSettings(objectsConfigMap)

    // Устанавливаем стейт на удаление
    for (const [key, value] of objectsConfigMap.entries()) {
      setStateKubernetesObjectsAllowDeletionTeamSettings({
        ...stateKubernetesObjectsAllowDeletionTeamSettings,
        [`allowDelete-${key}`]: value.allowDeletion,
      })
    }

    // Задаём параметры, установленные на уровне команды, для настроек сервиса
    if (props.action === EntityAction.Create) {
      if (props.teamPresettings && props.teamPresettings.kubernetesConfigRef) {
        if (props.teamPresettings.kubernetesConfigRef.objects.length > 0) {
          props.teamPresettings.kubernetesConfigRef.objects.forEach((obj) => {
            handleChangeCheckedAllowDelete(
              obj.object,
              obj.allowDeletion,
              'service'
            )
          })
        }
      } else {
        // По умолчанию выключено
        for (const key of mapKubernetesObjects.keys()) {
          handleChangeCheckedAllowDelete(key, false, 'service')
        }
      }
    }
  }, [props.teamPresettings])

  useEffect(() => {
    if (
      kubernetesNamespaces.length !== 0 &&
      ServiceStore.$('name').value !== ''
    ) {
      // Получаем выбранные имена
      const selectedKubernetesNamespaceNames: Array<string> = []
      for (const namespace of ServiceStore.$('namespaces').value) {
        selectedKubernetesNamespaceNames.push(namespace.name)
      }

      setSelectedNamespaces(selectedKubernetesNamespaceNames)
    }
  }, [
    kubernetesNamespaces,
    formattedKubernetesNamespaces,
    ServiceStore.$('name').value,
  ])

  useEffect(() => {
    const tmp: Array<KubernetesNamespaceSpec> = []

    for (const namespace of selectedNamespaces) {
      const result = getNamespaceByName(namespace)

      if (result !== undefined) {
        tmp.push(result)
      }
    }

    ServiceStore.$('namespaces').set(tmp)
  }, [selectedNamespaces])

  useEffect(() => {
    const config: ServiceKubernetesConfigSpec = ServiceStore.$(
      'kubernetesConfigRef'
    ).value
    config.objects = Array.from(mapKubernetesObjects, ([_, value]) => value)

    ServiceStore.$('kubernetesConfigRef.objects').reset()
    ServiceStore.$('kubernetesConfigRef').set({ ...config })
  }, [stateKubernetesObjectsAllowDeletion])

  return (
    <>
      <Typography
        variant="body1"
        sx={{
          paddingTop: '30px',
          paddingBottom: '15px',
          fontWeight: '600',
        }}
      >
        Kubernetes
      </Typography>
      <Grid>
        {loadingNamespaces || props.loadingTeamPresettings ? (
          <CircularProgress size={20} />
        ) : (
          <>
            {props.teamPresettings && (
              <>
                <Grid
                  sx={{
                    width: '800px',
                  }}
                >
                  <Divider>Настройки команды</Divider>
                </Grid>
                <UserInteractionCustomMultipleSelect
                  name="namespaces"
                  description="Namespaces"
                  helperText="Список namespace, настроенных на уровне команды"
                  options={formattedKubernetesNamespaces}
                  selected={selectedNamespacesTeamSettings}
                  setSelected={setSelectedNamespacesTeamSettings}
                  disabled
                />
                <UserInteractionSwitch
                  name={`allowDelete-${ServiceKubernetesConfigObjectsType.Pod}`}
                  helperText={`Возможность удаления подов по умолчанию`}
                  checked={
                    stateKubernetesObjectsAllowDeletionTeamSettings[
                      `allowDelete-${ServiceKubernetesConfigObjectsType.Pod}`
                    ] || false
                  }
                  description="Возможность удаления подов"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleChangeCheckedAllowDelete(
                      ServiceKubernetesConfigObjectsType.Pod,
                      event.target.checked,
                      'team'
                    )
                  }}
                  disabled
                />
              </>
            )}
            <Grid
              sx={{
                width: '800px',
              }}
            >
              <Divider>Настройки сервиса</Divider>
            </Grid>
            <UserInteractionCustomMultipleSelect
              name="namespaces"
              description="Namespaces"
              helperText="Выберите дополнительные namespace, в которых может быть развернут сервис"
              options={formattedKubernetesNamespacesAvaliableService}
              selected={selectedNamespaces}
              setSelected={setSelectedNamespaces}
            />
            <UserInteractionSwitch
              name={`allowDelete-${ServiceKubernetesConfigObjectsType.Pod}`}
              helperText={`Включить возможность удаления подов`}
              checked={
                stateKubernetesObjectsAllowDeletion[
                  `allowDelete-${ServiceKubernetesConfigObjectsType.Pod}`
                ] || false
              }
              description="Возможность удаления подов"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                handleChangeCheckedAllowDelete(
                  ServiceKubernetesConfigObjectsType.Pod,
                  event.target.checked,
                  'service'
                )
              }}
            />
          </>
        )}
      </Grid>
    </>
  )
})

export default Kubernetes

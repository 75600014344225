import { CircularProgress, Divider, Grid, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import ServiceStore from './store'
import {
  GeneralSettingsSpec,
  PrometheusConfigMetricBaseSpec,
  ServicePrometheusConfigMetricSpec,
  ServicePrometheusConfigSpec,
  TeamPrometheusConfigMetricSpec,
  TeamPrometheusConfigSpec,
} from '@edp/types'
import { runInAction } from 'mobx'
import { UserInteractionAddButton } from 'components/UserInteraction/UserInteractionAddButton'
import { EnvironmentSpec } from 'types/Managers/Environments'
import UserInteractionPrometheusMetricInput from 'components/UserInteraction/UserInteractionPrometheusMetricInput'
import { ApiClient } from 'api/ApiClient'
import _ from 'lodash'

export interface PrometheusProps {
  generalSettings?: GeneralSettingsSpec
  loadingGeneralSettings: boolean
}

interface ServicePrometheusMetricItemProps {
  generalPrometheusMetrics?: PrometheusConfigMetricBaseSpec[]
  teamPrometheusConfiguration?: TeamPrometheusConfigSpec
  environments: EnvironmentSpec[]
  metric: ServicePrometheusConfigMetricSpec
  index: number
}

const ServicePrometheusMetricItem = observer(
  (props: ServicePrometheusMetricItemProps) => {
    const [error, setError] = useState<boolean>(false)

    const handleChangeEnvironment = (index: number, value: string) => {
      runInAction(() => {
        const config: ServicePrometheusConfigSpec = {
          ...ServiceStore.$('prometheusConfigRef').value,
        }
        config.metrics[index].environment = value
        ServiceStore.$(`prometheusConfigRef`).set({ ...config })
        existMetric(config.metrics[index])
      })
    }

    const handleChangeName = (index: number, value: string) => {
      runInAction(() => {
        const config: ServicePrometheusConfigSpec = {
          ...ServiceStore.$('prometheusConfigRef').value,
        }
        config.metrics[index].name = value
        ServiceStore.$(`prometheusConfigRef`).set({ ...config })
        existMetric(config.metrics[index])
      })
    }

    const handleChangeMetric = (index: number, value: string) => {
      runInAction(() => {
        const config: ServicePrometheusConfigSpec = {
          ...ServiceStore.$('prometheusConfigRef').value,
        }
        config.metrics[index].metric = value
        ServiceStore.$(`prometheusConfigRef`).set({ ...config })
        existMetric(config.metrics[index])
      })
    }

    const handleChangeThreshold = (index: number, value: string) => {
      const validate = (value: string) => {
        if (value === '') {
          return true
        }

        const pattern = /^[-+]?(\d+(\.\d*)?|\.\d+)([eE][-+]?\d+)?$/
        return pattern.test(value)
      }

      runInAction(() => {
        if (!validate(value)) {
          return
        }

        const config: ServicePrometheusConfigSpec = {
          ...ServiceStore.$('prometheusConfigRef').value,
        }
        config.metrics[index].threshold = value
        ServiceStore.$(`prometheusConfigRef`).set({ ...config })
        existMetric(config.metrics[index])
      })
    }

    const handleChangeUnit = (index: number, value: string) => {
      runInAction(() => {
        const config: ServicePrometheusConfigSpec = {
          ...ServiceStore.$('prometheusConfigRef').value,
        }
        config.metrics[index].unit = value
        ServiceStore.$(`prometheusConfigRef`).set({ ...config })
        existMetric(config.metrics[index])
      })
    }

    const handleRemoveMetric = (index: number) => {
      runInAction(() => {
        const config: ServicePrometheusConfigSpec = {
          ...ServiceStore.$('prometheusConfigRef').value,
        }
        config.metrics.splice(index, 1)
        ServiceStore.$(`prometheusConfigRef`).reset()
        ServiceStore.$(`prometheusConfigRef`).set({ ...config })
      })
    }

    const existTeamMetric = (metric: ServicePrometheusConfigMetricSpec) => {
      if (
        props.teamPrometheusConfiguration &&
        props.teamPrometheusConfiguration.metrics.length !== 0
      ) {
        const index = _.findIndex(
          props.teamPrometheusConfiguration?.metrics,
          (obj) => {
            return (
              obj.environment === metric.environment &&
              obj.name === metric.name &&
              obj.metric === metric.metric &&
              obj.threshold === metric.threshold &&
              obj.unit === metric.unit
            )
          }
        )

        if (index === -1) {
          return false
        } else {
          return true
        }
      }
    }

    const existGeneralMetric = (metric: ServicePrometheusConfigMetricSpec) => {
      if (
        props.generalPrometheusMetrics &&
        props.generalPrometheusMetrics.length !== 0
      ) {
        const index = _.findIndex(props.generalPrometheusMetrics, (obj) => {
          return (
            obj.environment === metric.environment &&
            obj.name === metric.name &&
            obj.metric === metric.metric &&
            obj.threshold === metric.threshold &&
            obj.unit === metric.unit
          )
        })

        if (index === -1) {
          return false
        } else {
          return true
        }
      }
    }

    const existMetric = (metric: ServicePrometheusConfigMetricSpec) => {
      if (existTeamMetric(metric)) {
        setError(true)
      } else if (existGeneralMetric(metric)) {
        setError(true)
      } else {
        setError(false)
      }
    }

    return (
      <UserInteractionPrometheusMetricInput
        key={`prometheus-config-metric-${props.index}`}
        helperText={`Заполните параметры метрики. Запрос составляется в формате PromQL, результатом должно быть число.`}
        index={props.index}
        environments={props.environments}
        selectedEnvironment={props.metric.environment}
        second={props.metric.name}
        third={props.metric.metric}
        fourth={props.metric.threshold}
        fift={props.metric.unit}
        secondLabel="Название"
        thirdLabel="Метрика"
        fourthLabel="Порог"
        fiftLabel="Единица"
        error={error}
        handleChangeEnvironment={(e) => {
          handleChangeEnvironment(props.index, e.toString())
        }}
        handleChangeSecond={(e) => {
          handleChangeName(props.index, e.target.value)
        }}
        handleChangeThird={(e) => {
          handleChangeMetric(props.index, e.target.value)
        }}
        handleChangeFourth={(e) => {
          handleChangeThreshold(props.index, e.target.value)
        }}
        handleChangeFift={(e) => {
          handleChangeUnit(props.index, e.target.value)
        }}
        removeButton
        handleRemove={() => {
          handleRemoveMetric(props.index)
        }}
      />
    )
  }
)

const Prometheus = observer((props: PrometheusProps) => {
  const [environments, setEnvironments] = useState<EnvironmentSpec[]>()
  const [loadingEnvironments, setLoadingEnvironments] = useState<boolean>(false)

  const [teamPrometheusConfiguration, setTeamPrometheusConfiguration] =
    useState<TeamPrometheusConfigSpec>()
  const [
    loadingTeamPrometheusConfiguration,
    setLoadingTeamPrometheusConfiguration,
  ] = useState<boolean>(false)

  useEffect(() => {
    const apiClient = new ApiClient()

    const getEnvironments = async () => {
      try {
        setLoadingEnvironments(true)
        const data = await apiClient.getEnvironments()
        setEnvironments(data)
      } catch (e) {
        console.log(e)
      } finally {
        setLoadingEnvironments(false)
      }
    }

    getEnvironments()
  }, [])

  useEffect(() => {
    const apiClient = new ApiClient()

    const getTeamPrometheusMetrics = async () => {
      try {
        setLoadingTeamPrometheusConfiguration(true)
        const data = await apiClient.getTeamPrometheusMetricsConfigurations(
          ServiceStore.$('owner').value
        )
        setTeamPrometheusConfiguration(data)
      } catch (e) {
        console.log(e)
      } finally {
        setLoadingTeamPrometheusConfiguration(false)
      }
    }

    getTeamPrometheusMetrics()
  }, [ServiceStore.$('owner').value])

  const handleAddMetric = () => {
    const metrics: ServicePrometheusConfigMetricSpec[] = ServiceStore.$(
      'prometheusConfigRef.metrics'
    ).value

    if (metrics.length != 0) {
      if (
        metrics[metrics.length - 1].name !== '' ||
        metrics[metrics.length - 1].metric !== ''
      ) {
        const config: ServicePrometheusConfigSpec = {
          ...ServiceStore.$('prometheusConfigRef').value,
        }
        config.metrics.push({
          service: '',
          environment: '',
          name: '',
          metric: '',
          threshold: '',
          unit: '',
        })
        ServiceStore.$(`prometheusConfigRef`).reset()
        ServiceStore.$(`prometheusConfigRef`).set({ ...config })
      }
    } else {
      const config: ServicePrometheusConfigSpec = {
        ...ServiceStore.$('prometheusConfigRef').value,
      }
      config.metrics = [
        {
          service: '',
          environment: '',
          name: '',
          metric: '',
          threshold: '',
          unit: '',
        },
      ]
      ServiceStore.$(`prometheusConfigRef`).reset()
      ServiceStore.$(`prometheusConfigRef`).set({ ...config })
    }
  }

  return (
    <>
      <Typography
        variant="body1"
        paddingTop="30px"
        className="Typography-Body1"
      >
        Prometheus
      </Typography>
      {!loadingEnvironments &&
      !loadingTeamPrometheusConfiguration &&
      !props.loadingGeneralSettings ? (
        <>
          {props.generalSettings &&
            props.generalSettings.prometheusMetrics.length > 0 && (
              <Grid
                sx={{
                  marginBottom: '10px',
                }}
              >
                <Grid
                  sx={{
                    width: '800px',
                  }}
                >
                  <Divider>Общие метрики</Divider>
                </Grid>
                {props.generalSettings.prometheusMetrics.map(
                  (metric: PrometheusConfigMetricBaseSpec, index: number) => {
                    return (
                      <UserInteractionPrometheusMetricInput
                        key={`general-prometheus-config-metric-${index}`}
                        helperText={`Заполните параметры метрики. Запрос составляется в формате PromQL, результатом должно быть число.`}
                        index={index}
                        environments={environments}
                        selectedEnvironment={metric.environment}
                        second={metric.name}
                        third={metric.metric}
                        fourth={metric.threshold}
                        fift={metric.unit}
                        secondLabel="Название"
                        thirdLabel="Метрика"
                        fourthLabel="Порог"
                        fiftLabel="Единица"
                        environmentDisabled={true}
                        secondReadOnly={true}
                        secondDisabled={true}
                        thirdReadOnly={true}
                        thirdDisabled={true}
                        fourthReadOnly={true}
                        fourthDisabled={true}
                        fiftReadOnly={true}
                        fiftDisabled={true}
                      />
                    )
                  }
                )}
              </Grid>
            )}
          {teamPrometheusConfiguration &&
            teamPrometheusConfiguration.metrics.length > 0 && (
              <Grid
                sx={{
                  marginBottom: '10px',
                }}
              >
                <Grid
                  sx={{
                    width: '800px',
                  }}
                >
                  <Divider>Метрики команды</Divider>
                </Grid>
                {teamPrometheusConfiguration.metrics.map(
                  (metric: TeamPrometheusConfigMetricSpec, index: number) => {
                    return (
                      <UserInteractionPrometheusMetricInput
                        key={`prometheus-config-metric-${index}`}
                        helperText={`Заполните параметры метрики. Запрос составляется в формате PromQL, результатом должно быть число.`}
                        index={index}
                        environments={environments}
                        selectedEnvironment={metric.environment}
                        second={metric.name}
                        third={metric.metric}
                        fourth={metric.threshold}
                        fift={metric.unit}
                        secondLabel="Название"
                        thirdLabel="Метрика"
                        fourthLabel="Порог"
                        fiftLabel="Единица"
                        environmentDisabled={true}
                        secondReadOnly={true}
                        secondDisabled={true}
                        thirdReadOnly={true}
                        thirdDisabled={true}
                        fourthReadOnly={true}
                        fourthDisabled={true}
                        fiftReadOnly={true}
                        fiftDisabled={true}
                      />
                    )
                  }
                )}
                {}
              </Grid>
            )}
          <Grid
            sx={{
              width: '800px',
            }}
          >
            <Divider>Метрики сервиса</Divider>
          </Grid>
          {ServiceStore.$('prometheusConfigRef.metrics').value && (
            <>
              {ServiceStore.$('prometheusConfigRef.metrics').value.length > 0 &&
                ServiceStore.$('prometheusConfigRef.metrics').value.map(
                  (
                    metric: ServicePrometheusConfigMetricSpec,
                    index: number
                  ) => {
                    return (
                      <ServicePrometheusMetricItem
                        generalPrometheusMetrics={
                          props.generalSettings?.prometheusMetrics
                        }
                        teamPrometheusConfiguration={
                          teamPrometheusConfiguration
                        }
                        environments={environments || []}
                        metric={metric}
                        index={index}
                      />
                    )
                  }
                )}
            </>
          )}
          <UserInteractionAddButton handleAdd={handleAddMetric} />
        </>
      ) : (
        <>
          <CircularProgress size={20} />
        </>
      )}
    </>
  )
})

export default Prometheus

import React, { forwardRef, useEffect, useState } from 'react'

import {
  CircularProgress,
  Grid,
  Radio,
  TextField,
  Tooltip,
} from '@mui/material'
import { HelpOutline } from '@edp/core-common-frontend/dist/packages/mui/icons-material'

import './styles.css'
import { CHANGE_ME_PLACEHOLDER } from 'types/other'
import { UserInteractionRemoveButton } from './UserInteractionRemoveButton'
import { EnvironmentSpec } from 'types/Managers/Environments'
import { ApiClient } from 'api/ApiClient'
import {
  CustomSelectComponentUUID,
  CustomSelectComponentUUIDOption,
} from '@edp/core-common-frontend'

export interface UserInteractionPrometheusMetricInputProps {
  environmentDisabled?: boolean | undefined
  secondDisabled?: boolean | undefined
  thirdDisabled?: boolean | undefined
  fourthDisabled?: boolean | undefined
  fiftDisabled?: boolean | undefined
  error?: boolean
  environments?: EnvironmentSpec[] | undefined
  selectedEnvironment: string
  second: any
  third: any
  fourth: any
  fift: any
  index?: number
  handleChangeEnvironment?: React.Dispatch<React.SetStateAction<string>>
  handleChangeSecond?: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleChangeThird?: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleChangeFourth?: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleChangeFift?: (event: React.ChangeEvent<HTMLInputElement>) => void
  secondLabel?: string
  thirdLabel?: string
  fourthLabel?: string
  fiftLabel?: string
  required?: boolean
  rows?: number
  height?: string
  secondReadOnly?: boolean
  thirdReadOnly?: boolean
  fourthReadOnly?: boolean
  fiftReadOnly?: boolean
  type?: string
  helperText?: string
  radioButton?: boolean
  radioButtonTooltipText?: string
  selectedRadioButton?: string
  setSelectedRadioButton?: (data: string) => void
  removeButton?: boolean
  handleRemove?: () => void
}

const UserInteractionPrometheusMetricInput = forwardRef(
  // eslint-disable-next-line
  (props: UserInteractionPrometheusMetricInputProps, _ref) => {
    const handleChangeRadioButton = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      if (
        props.setSelectedRadioButton !== undefined &&
        props.index !== undefined
      ) {
        props.setSelectedRadioButton(String(props.index))
      }
    }

    const [environments, setEnvironments] = useState<EnvironmentSpec[]>()
    const [optionsEnvironment, setOptionsEnvironment] = useState<
      CustomSelectComponentUUIDOption[]
    >([])
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<boolean>(false)

    const handleChangeEnvironmentMock: React.Dispatch<
      React.SetStateAction<string>
    > = () => {}

    useEffect(() => {
      const apiClient = new ApiClient()

      const getEnvironments = async () => {
        try {
          setLoading(true)
          const data = await apiClient.getEnvironments()
          setEnvironments(data)
        } catch (e) {
          console.log(e)
          setError(true)
        } finally {
          setLoading(false)
        }
      }

      if (!props.environments) {
        getEnvironments()
      }
    }, [])

    useEffect(() => {
      if (props.environments) {
        setEnvironments(props.environments)
      }
    })

    useEffect(() => {
      const optionsEnvironment: CustomSelectComponentUUIDOption[] =
        environments?.map((o) => ({
          label: o.name,
          uuid: o.uuid,
        })) || []

      setOptionsEnvironment(optionsEnvironment)
    }, [environments])

    return (
      <>
        {!loading ? (
          <Grid className="UserInteraction-Grid-Remove">
            <Grid
              className="UserInteraction-PrometheusMetric-Grid"
              style={{
                width: `${props.radioButton ? '838px' : '800px'}`,
                marginLeft: `${props.radioButton ? '-15px' : '0'}`,
              }}
            >
              {props.radioButton && (
                <Tooltip
                  arrow
                  placement="top"
                  title={props.radioButtonTooltipText}
                >
                  <div>
                    <Radio
                      checked={
                        String(props.selectedRadioButton) ===
                        String(props.index)
                      }
                      size="small"
                      onChange={handleChangeRadioButton}
                      value={props.index}
                      name="radio-buttons"
                      inputProps={{ 'aria-label': 'A' }}
                      sx={{
                        width: '18px',
                        height: '18px',
                      }}
                    />
                  </div>
                </Tooltip>
              )}
              <CustomSelectComponentUUID
                options={optionsEnvironment}
                selected={props.selectedEnvironment}
                title="Окружение"
                error={props.error || error}
                setSelected={
                  props.handleChangeEnvironment || handleChangeEnvironmentMock
                }
                disabled={props.environmentDisabled}
                width="180px"
              />
              <TextField
                key={`${props.index}-second`}
                name={props.second}
                className="UserInteraction-PrometheusMetricInput-Second"
                label={props.secondLabel}
                value={props.second}
                error={props.second === CHANGE_ME_PLACEHOLDER || props.error}
                type={props.type}
                onChange={props.handleChangeSecond}
                required={props.required || false}
                disabled={props.secondDisabled}
                InputProps={{
                  readOnly: props.secondReadOnly,
                }}
                InputLabelProps={{
                  shrink: true,
                  sx: {
                    fontSize: '10px',
                    '&.MuiInputLabel-shrink': {
                      transform: 'translate(14px, -5px) scale(0.75)',
                    },
                  },
                }}
                sx={{
                  '& .MuiInputBase-root': {
                    height: `${props.height || '40px'}`,
                  },
                  '& .MuiOutlinedInput-root': {
                    fontSize: '10px',
                  },
                  '& .MuiOutlinedInput-input': {
                    fontSize: '15px',
                  },
                }}
              />
              <TextField
                key={`${props.index}-third`}
                name={props.third}
                className="UserInteraction-PrometheusMetricInput-Third"
                label={props.thirdLabel}
                value={props.third}
                error={props.third === CHANGE_ME_PLACEHOLDER || props.error}
                type={props.type}
                onChange={props.handleChangeThird}
                required={props.required || false}
                disabled={props.thirdDisabled}
                InputProps={{
                  readOnly: props.thirdReadOnly,
                }}
                InputLabelProps={{
                  shrink: true,
                  sx: {
                    fontSize: '10px',
                    '&.MuiInputLabel-shrink': {
                      transform: 'translate(14px, -5px) scale(0.75)',
                    },
                  },
                }}
                sx={{
                  '& .MuiInputBase-root': {
                    height: `${props.height || '40px'}`,
                  },
                  '& .MuiOutlinedInput-root': {
                    fontSize: '10px',
                  },
                  '& .MuiOutlinedInput-input': {
                    fontSize: '15px',
                  },
                }}
              />
              <TextField
                key={`${props.index}-fourth`}
                name={props.fourth}
                className="UserInteraction-PrometheusMetricInput-Fourth"
                label={props.fourthLabel}
                value={props.fourth}
                error={props.fourth === CHANGE_ME_PLACEHOLDER || props.error}
                onChange={props.handleChangeFourth}
                required={props.required || false}
                disabled={props.fourthDisabled}
                InputProps={{
                  readOnly: props.fourthReadOnly,
                }}
                InputLabelProps={{
                  shrink: true,
                  sx: {
                    fontSize: '10px',
                    '&.MuiInputLabel-shrink': {
                      transform: 'translate(14px, -5px) scale(0.75)',
                    },
                  },
                }}
                sx={{
                  '& .MuiInputBase-root': {
                    height: `${props.height || '40px'}`,
                  },
                  '& .MuiOutlinedInput-root': {
                    fontSize: '10px',
                  },
                  '& .MuiOutlinedInput-input': {
                    fontSize: '15px',
                  },
                }}
              />
              <TextField
                key={`${props.index}-fift`}
                name={props.fift}
                className="UserInteraction-PrometheusMetricInput-Fift"
                label={props.fiftLabel}
                value={props.fift}
                error={props.fift === CHANGE_ME_PLACEHOLDER || props.error}
                type={props.type}
                onChange={props.handleChangeFift}
                required={props.required || false}
                disabled={props.fiftDisabled}
                InputProps={{
                  readOnly: props.fiftReadOnly,
                }}
                InputLabelProps={{
                  shrink: true,
                  sx: {
                    fontSize: '10px',
                    '&.MuiInputLabel-shrink': {
                      transform: 'translate(14px, -5px) scale(0.75)',
                    },
                  },
                }}
                sx={{
                  '& .MuiInputBase-root': {
                    height: `${props.height || '40px'}`,
                  },
                  '& .MuiOutlinedInput-root': {
                    fontSize: '10px',
                  },
                  '& .MuiOutlinedInput-input': {
                    fontSize: '15px',
                  },
                }}
              />
            </Grid>

            <Tooltip arrow title={props.helperText} placement="top">
              <HelpOutline className="UserInteraction-ThreeTextFields-HelpIcon"></HelpOutline>
            </Tooltip>
            {props.removeButton && (
              <UserInteractionRemoveButton handleRemove={props.handleRemove} />
            )}
          </Grid>
        ) : (
          <>
            <CircularProgress size={20} />
          </>
        )}
      </>
    )
  }
)

UserInteractionPrometheusMetricInput.displayName =
  'UserInteractionPrometheusMetricInput'

export default UserInteractionPrometheusMetricInput

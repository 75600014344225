import { CustomCardHeader } from '@edp/core-common-frontend'
import {
  BugReport,
  ContentCopy,
  DataObject,
  LockOpen,
  SentimentVeryDissatisfied,
} from '@edp/core-common-frontend/dist/packages/mui/icons-material'
import {
  Card,
  CardContent,
  Chip,
  Grid,
  IconButton,
  useTheme,
} from '@edp/core-common-frontend/dist/packages/mui/material'
import { React } from '@edp/core-common-frontend/dist/packages/react'
import {
  ServiceSonarqubeFindingsResultSpec,
  SonarqubeMeasure,
  SonarqubeMetrics,
} from '@edp/types'
import { PercentageNegative, PercentagePositive } from './Percentage'
import { Rating } from './Rating'
import { RatingCard } from './RatingCard'
import { Value } from './Value'
import { useEffect } from 'react'
import { MdOutlineLink } from '@edp/core-common-frontend/dist/packages/react-icons/md'

export interface SonarqubeCardProps {
  spec: ServiceSonarqubeFindingsResultSpec
}

export const SonarqubeCard = (props: SonarqubeCardProps) => {
  const theme = useTheme()
  const [value, setValue] = React.useState<any>()

  const metrics: SonarqubeMetrics = {
    alert_status: undefined,
    bugs: undefined,
    reliability_rating: undefined,
    vulnerabilities: undefined,
    security_rating: undefined,
    security_hotspots_reviewed: undefined,
    security_review_rating: undefined,
    code_smells: undefined,
    sqale_rating: undefined,
    coverage: undefined,
    duplicated_lines_density: undefined,
  }

  useEffect(() => {
    const fetchFindings = async () => {
      try {
        props.spec.findings.measures.forEach((m: SonarqubeMeasure) => {
          metrics[m.metric] = m.value
        })

        setValue({
          lastAnalysis: props.spec.findings.analysisDate,
          metrics,
        })
      } catch (e) {
        console.log(e)
      }
    }

    fetchFindings()
  }, [props.spec])

  let gateLabel = 'Quality Gate: неизвестно'
  let gateColor = theme.palette.error.light

  if (value?.metrics.alert_status) {
    const gatePassed = value.metrics.alert_status === 'OK'
    gateLabel = gatePassed
      ? 'Quality Gate: пройден'
      : 'Quality Gate: не пройден'
    gateColor = gatePassed
      ? theme.palette.success.light
      : theme.palette.error.light
  }

  return (
    <Card>
      <CustomCardHeader
        height="80px"
        title={`Проект: ${props.spec.project}`}
        description={`Основная статистика по сервису из SonarQube ${props.spec.instance}.`}
        fullPage={false}
      />
      <CardContent>
        <Grid
          item
          container
          direction="column"
          justifyContent="space-between"
          alignItems="center"
          style={{ height: '100%' }}
          spacing={0}
        >
          <Grid item container justifyContent="space-around">
            <RatingCard
              titleIcon={<BugReport />}
              title="Баги"
              leftSlot={<Rating rating={value?.metrics.reliability_rating} />}
              rightSlot={
                <Value
                  value={
                    value?.metrics.bugs !== undefined
                      ? `${value.metrics.bugs}`
                      : '—'
                  }
                />
              }
            />
            <RatingCard
              titleIcon={<LockOpen />}
              title="Уязвимости"
              leftSlot={<Rating rating={value?.metrics.security_rating} />}
              rightSlot={
                <Value
                  value={
                    value?.metrics.vulnerabilities !== undefined
                      ? `${value.metrics.vulnerabilities}`
                      : '—'
                  }
                />
              }
            />
            <RatingCard
              titleIcon={<SentimentVeryDissatisfied />}
              title="Проблемный код"
              leftSlot={<Rating rating={value?.metrics.sqale_rating} />}
              rightSlot={
                <Value
                  value={
                    value?.metrics.code_smells !== undefined
                      ? `${value.metrics.code_smells}`
                      : '—'
                  }
                />
              }
            />
            <RatingCard
              titleIcon={<DataObject />}
              title="Покрытие тестами"
              leftSlot={<PercentagePositive value={value?.metrics.coverage} />}
              rightSlot={
                <Value
                  value={
                    value?.metrics.coverage !== undefined
                      ? `${value.metrics.coverage}%`
                      : '—'
                  }
                />
              }
            />
            <RatingCard
              titleIcon={<ContentCopy />}
              title="Дублирование"
              leftSlot={
                <PercentageNegative
                  value={value?.metrics.duplicated_lines_density}
                />
              }
              rightSlot={
                <Value
                  value={
                    value?.metrics.duplicated_lines_density !== undefined
                      ? `${value.metrics.duplicated_lines_density}%`
                      : '—'
                  }
                />
              }
            />
          </Grid>
          <div style={{ paddingTop: '15px' }}>
            Время последнего анализа:{' '}
            {value?.lastAnalysis ? (
              <>
                {new Date(value.lastAnalysis).toLocaleString('ru-RU', {
                  timeZone: 'UTC',
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: false,
                })}
              </>
            ) : (
              'никогда'
            )}
          </div>
          <Grid
            container
            sx={{
              paddingTop: '15px',
            }}
          >
            <Grid
              item
              sm={12}
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Chip
                label={gateLabel}
                sx={{ backgroundColor: gateColor, color: '#ffffff' }}
              />
            </Grid>
            <Grid
              item
              sm={12}
              sx={{
                marginTop: '-40px',
              }}
            >
              <IconButton
                href={`${props.spec.url.endsWith('/') ? props.spec.url.slice(0, -1) : props.spec.url}/dashboard?id=${props.spec.project}`}
                target="_blank"
                sx={{
                  float: 'right',
                  marginTop: '5px',
                }}
              >
                <MdOutlineLink />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

import { observer } from 'mobx-react-lite'
import ServiceStore from './store'
import { Divider, Grid, Typography } from '@mui/material'
import {
  ServiceHelmRelease,
  TeamPresettingsConfigHelmReleaseSpec,
  TeamPresettingsConfigSpec,
} from '@edp/types'
import UserInteractionTextField from 'components/UserInteraction/UserInteractionTextField'
import { runInAction } from 'mobx'
import { UserInteractionAddButton } from 'components/UserInteraction/UserInteractionAddButton'
import { EntityAction } from 'types/entities'

type HelmReleaseProps = {
  action: EntityAction
  disabled: boolean
  teamPresettings?: TeamPresettingsConfigSpec
  loadingTeamPresettings: boolean
}

const HelmRelease = observer((props: HelmReleaseProps) => {
  const handleAddHelmRelease = () => {
    const helmReleases: ServiceHelmRelease[] =
      ServiceStore.$('helmReleases').value
    if (helmReleases && helmReleases.length != 0) {
      if (helmReleases[helmReleases.length - 1].name !== '') {
        const config: ServiceHelmRelease[] =
          ServiceStore.$('helmReleases').value
        config.push({ service: '', name: '' })
        ServiceStore.$(`helmReleases`).reset()
        ServiceStore.$(`helmReleases`).set(config)
      }
    } else {
      const config: ServiceHelmRelease[] = []
      config.push({ service: '', name: '' })
      ServiceStore.$(`helmReleases`).reset()
      ServiceStore.$(`helmReleases`).set(config)
    }
  }

  const handleChangeHelmRelease = (index: number, value: string) => {
    runInAction(() => {
      const config: ServiceHelmRelease[] = ServiceStore.$('helmReleases').value
      config[index].name = value
      ServiceStore.$(`helmReleases`).set(config)
    })
  }

  const handleRemoveHelmRelease = (index: number) => {
    runInAction(() => {
      const config: ServiceHelmRelease[] = ServiceStore.$('helmReleases').value
      config.splice(index, 1)
      ServiceStore.$('helmReleases').reset()
      ServiceStore.$('helmReleases').set(config)
    })
  }

  return (
    <>
      <Typography
        variant="body1"
        sx={{
          paddingTop: '30px',
          paddingBottom: '15px',
          fontWeight: '600',
        }}
      >
        Helm релизы
      </Typography>
      {props.teamPresettings &&
        props.teamPresettings.helmReleases &&
        props.teamPresettings.helmReleases.length > 0 && (
          <>
            <Grid
              sx={{
                width: '800px',
              }}
            >
              <Divider>Настройки команды</Divider>
            </Grid>
            <Grid>
              {props.teamPresettings.helmReleases.map(
                (
                  value: TeamPresettingsConfigHelmReleaseSpec,
                  index: number
                ) => {
                  return (
                    <UserInteractionTextField
                      name={'Название'}
                      description={'Название Helm release'}
                      helperText={'Название Helm release'}
                      value={value.name}
                      disabled
                    />
                  )
                }
              )}
            </Grid>
          </>
        )}
      <Grid
        sx={{
          width: '800px',
          marginTop: '10px',
        }}
      >
        <Divider>Настройки сервиса</Divider>
      </Grid>
      <Grid>
        {ServiceStore.$('helmReleases').value.length > 0 &&
          ServiceStore.$('helmReleases').value.map(
            (value: ServiceHelmRelease, index: number) => {
              return (
                <UserInteractionTextField
                  name={'Название'}
                  description={'Название Helm release'}
                  helperText={'Название Helm release'}
                  value={value.name}
                  onChange={(e) => {
                    handleChangeHelmRelease(index, e.target.value)
                  }}
                  removeButton
                  handleRemove={() => {
                    handleRemoveHelmRelease(index)
                  }}
                />
              )
            }
          )}
        <UserInteractionAddButton handleAdd={handleAddHelmRelease} />
      </Grid>
    </>
  )
})

export default HelmRelease

import {
  KubernetesNamespaceSpec,
  ServiceHelmRelease,
  ServiceInfoSpec,
  ServiceSpec,
  ServiceSwaggerConfigType,
} from '@edp/types'
import { ApiClient } from 'api/ApiClient'

export enum StatusFillingServiceFields {
  Namespaces = 'Namespaces',
  HelmReleases = 'HelmReleases',
  Swagger = 'Swagger',
  Lifecycle = 'Lifecycle',
  Grafana = 'Grafana',
  Registry = 'Registry',
}

export interface StatusFillingServiceSpec {
  message: string
  filledFields: Array<string>
  allFields: number
}

// Grafana
const PLUGIN_GRAFANA_PANEL_URL_ANNOTATION =
  'edp.express42.com/grafana-dashboard-url'

// Docker registry
const PLUGIN_ARTIFACT_PANEL_DOCKER_REGISTRY_URL =
  'edp.express42.com/docker-registry-url'
const PLUGIN_ARTIFACT_PANEL_DOCKER_REGISTRY_NAME =
  'edp.express42.com/docker-registry-name'
const PLUGIN_ARTIFACT_PANEL_DOCKER_REGISTRY_TYPE =
  'edp.express42.com/docker-registry-type'
const PLUGIN_ARTIFACT_PANEL_DOCKER_IMAGE_NAME =
  'edp.express42.com/docker-image-name'

const fetchServiceInfo = async (
  uuid?: string
): Promise<ServiceInfoSpec | undefined> => {
  if (uuid) {
    const apiClient = new ApiClient()

    try {
      return await apiClient.getServiceInfo(uuid)
    } catch (e) {
      console.error('Ошибка при получении данных: ', e)
      return undefined
    }
  }
}

const checkExistGrafana = (serviceInfo?: ServiceInfoSpec): boolean => {
  if (serviceInfo === undefined) {
    return false
  }

  const tmpServiceInfo = serviceInfo as any

  let url = undefined

  if (tmpServiceInfo.metadata !== undefined) {
    url =
      tmpServiceInfo.metadata.annotations[PLUGIN_GRAFANA_PANEL_URL_ANNOTATION]
  }

  if (serviceInfo.spec !== undefined) {
    if (serviceInfo.spec.grafana !== undefined) {
      return true
    } else {
      return false
    }
  } else if (url !== undefined) {
    return true
  } else {
    return false
  }
}

const checkExistRegistry = (serviceInfo?: ServiceInfoSpec): boolean => {
  if (serviceInfo === undefined) {
    return false
  }

  const tmpServiceInfo = serviceInfo as any

  let registryURL = undefined
  let registryName = undefined
  let registryType = undefined
  let imageName = undefined

  if (tmpServiceInfo.metadata !== undefined) {
    registryURL =
      tmpServiceInfo.metadata.annotations[
        PLUGIN_ARTIFACT_PANEL_DOCKER_REGISTRY_URL
      ]
    registryName =
      tmpServiceInfo.metadata.annotations[
        PLUGIN_ARTIFACT_PANEL_DOCKER_REGISTRY_NAME
      ]
    registryType =
      tmpServiceInfo.metadata.annotations[
        PLUGIN_ARTIFACT_PANEL_DOCKER_REGISTRY_TYPE
      ]
    imageName =
      tmpServiceInfo.metadata.annotations[
        PLUGIN_ARTIFACT_PANEL_DOCKER_IMAGE_NAME
      ]
  }

  if (
    registryURL === undefined ||
    registryName === undefined ||
    registryType === undefined ||
    imageName === undefined
  ) {
    return false
  } else {
    return true
  }
}

const checkExistNamespaces = async (uuid?: string): Promise<boolean> => {
  if (uuid) {
    const apiClient = new ApiClient()

    try {
      const data: KubernetesNamespaceSpec[] =
        await apiClient.getServiceNamespaces(uuid)

      if (data) {
        if (data.length > 0) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    } catch (e) {
      console.error('Ошибка при получении данных: ', e)
      return false
    }
  }

  return false
}

const checkExistHelmReleases = async (uuid?: string): Promise<boolean> => {
  if (uuid) {
    const apiClient = new ApiClient()

    try {
      const data: ServiceHelmRelease[] = await apiClient.getServiceHelmReleases(
        uuid
      )

      if (data) {
        if (data.length > 0) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    } catch (e) {
      console.error('Ошибка при получении данных: ', e)
      return false
    }
  }

  return false
}

export const countingFillingServiceFieldsStatus = async (
  service: ServiceSpec
): Promise<StatusFillingServiceSpec> => {
  const result: StatusFillingServiceSpec = {
    message: '',
    filledFields: [],
    allFields: Object.entries(StatusFillingServiceFields).length,
  }

  const notFoundInfo: Array<string> = []

  // Namespaces
  const namespacesExist = await checkExistNamespaces(service.uuid)

  if (namespacesExist) {
    result.filledFields.push(StatusFillingServiceFields.Namespaces)
  } else {
    notFoundInfo.push('Не добавлены namespaces')
  }

  // Helm releases
  const helmReleasesExist = await checkExistHelmReleases(service.uuid)

  if (helmReleasesExist) {
    result.filledFields.push(StatusFillingServiceFields.HelmReleases)
  } else {
    notFoundInfo.push('Не добавлены Helm release')
  }

  if (
    service.swaggerConfigRef != undefined &&
    service.swaggerConfigRef.type !== ''
  ) {
    if (service.swaggerConfigRef.type === ServiceSwaggerConfigType.Repository) {
      result.filledFields.push(StatusFillingServiceFields.Swagger)
    } else if (
      service.swaggerConfigRef.type === ServiceSwaggerConfigType.Link
    ) {
      if (service.swaggerConfigRef.links.length !== 0) {
        result.filledFields.push(StatusFillingServiceFields.Swagger)
      } else {
        notFoundInfo.push('Не указаны ссылки на конфигурацию Swagger')
      }
    } else {
      notFoundInfo.push('Не добавлена конфигурация Swagger')
    }
  } else {
    notFoundInfo.push('Не добавлена конфигурация Swagger')
  }

  if (service.lifecycleStage != undefined) {
    result.filledFields.push(StatusFillingServiceFields.Lifecycle)
  } else {
    notFoundInfo.push('Не указана стадия жизненного цикла')
  }

  // Other

  const serviceInfo: ServiceInfoSpec | undefined = await fetchServiceInfo(
    service.uuid
  )

  // Grafana
  const grafanaExist = checkExistGrafana(serviceInfo)

  if (grafanaExist) {
    result.filledFields.push(StatusFillingServiceFields.Grafana)
  } else {
    notFoundInfo.push('Не добавлена конфигурация Grafana')
  }

  // Registry
  const registryExist = checkExistRegistry(serviceInfo)

  if (registryExist) {
    result.filledFields.push(StatusFillingServiceFields.Registry)
  } else {
    notFoundInfo.push('Не добавлена конфигурация Registry')
  }

  let fullMessage = 'Сервис имеет незаполненные поля:'

  for (const message of notFoundInfo) {
    fullMessage = `${fullMessage}\n* ${message}`
  }

  result.message = fullMessage

  return result
}

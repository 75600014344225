import React from 'react'
import { HelpOutline } from '@edp/core-common-frontend/dist/packages/mui/icons-material'
import { Grid, Typography, Tooltip } from '@mui/material'
import './styles.css'
import { CustomMultipleSelectComponent } from '../CustomMultipleSelectComponent'

export interface UserInteractionCustomMultipleSelectProps {
  name: string
  description: string
  options: string[]
  helperText: string
  selected: string[]
  setSelected: React.Dispatch<React.SetStateAction<string[]>>
  disabled?: boolean
}

const UserInteractionCustomMultipleSelect = (
  props: UserInteractionCustomMultipleSelectProps
) => {
  return (
    <Grid className="UserInteraction-Grid">
      <Grid className="UserInteraction-Description">
        <Typography variant="body2" fontSize={'15px'}>
          {props.description}
        </Typography>
        <Tooltip arrow title={props.helperText} placement="top">
          <HelpOutline className="UserInteraction-HelpIcon"></HelpOutline>
        </Tooltip>
      </Grid>
      <CustomMultipleSelectComponent
        options={props.options}
        selected={props.selected}
        setSelected={props.setSelected}
        disabled={props.disabled}
      />
    </Grid>
  )
}

export default UserInteractionCustomMultipleSelect

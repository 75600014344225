import { CustomCardHeader, useFetchService } from '@edp/core-common-frontend'
import {
  HelmNamespaceReleases,
  HelmReleaseStatus,
  KubernetesNamespaceSpec,
} from '@edp/types'
import {
  Alert,
  Card,
  CardContent,
  Chip,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { ApiClient } from 'api/ApiClient'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

enum EnvironmentStatus {
  NotFound = 'Helm release отсутствуют',
  Success = 'Всё хорошо',
  Warning = 'Требует внимания',
  Failed = 'Проблемы',
  Pending = 'Ожидание',
}

export const EnvironmentStatusColors = {
  [EnvironmentStatus.NotFound]: {
    backgroundColor: '#0288d1',
    color: '#FFFFFF',
  },
  [EnvironmentStatus.Success]: {
    backgroundColor: '#2e7d32',
    color: '#FFFFFF',
  },
  [EnvironmentStatus.Warning]: {
    backgroundColor: '#ed6c02',
    color: '#FFFFFF',
  },
  [EnvironmentStatus.Failed]: {
    backgroundColor: '#d32f2f',
    color: '#FFFFFF',
  },
  [EnvironmentStatus.Pending]: {
    backgroundColor: '#9c27b0',
    color: '#FFFFFF',
  },
}

export interface EnvironmentStatusCardProps {}

export interface EnvironmentStatusRowProps {
  environmentStatus: EnvironmentStatusSpec
}

export interface EnvironmentStatusSpec {
  name: string
  namespaces: Array<string>
  status: string
}

export interface StatusCountsSpec {
  warnings: number
  pendings: number
  errors: number
  success: number
}

const EnvironmentStatusRow = (props: EnvironmentStatusRowProps) => {
  return (
    <>
      <TableRow>
        <TableCell align="left">{props.environmentStatus.name}</TableCell>
        <TableCell align="left">
          <List dense={true}>
            {Array.from(props.environmentStatus.namespaces).map((value) => {
              return (
                <ListItem
                  sx={{
                    padding: 0,
                  }}
                >
                  <ListItemText primary={value} />
                </ListItem>
              )
            })}
          </List>
        </TableCell>
        <TableCell align="left">
          <Chip
            label={props.environmentStatus.status}
            sx={{
              backgroundColor:
                EnvironmentStatusColors[
                  props.environmentStatus.status as EnvironmentStatus
                ].backgroundColor,
              color:
                EnvironmentStatusColors[
                  props.environmentStatus.status as EnvironmentStatus
                ].color,
            }}
          />
        </TableCell>
      </TableRow>
    </>
  )
}

export const EnvironmentsStatusCard = (props: EnvironmentStatusCardProps) => {
  const { uuid } = useParams()
  const apiClient = new ApiClient()

  const service = useFetchService(uuid)

  const [serviceNamespaces, setServiceNamespaces] = useState<
    KubernetesNamespaceSpec[]
  >([])
  const [loadingServiceNamespaces, setLoadingServiceNamespaces] =
    useState<boolean>(false)
  const [failedServiceNamespaces, setFailedServiceNamespaces] =
    useState<boolean>(false)
  const [errorMessageServiceNamespaces, setErrorMessageServiceNamespaces] =
    useState<string>('')

  const [helmNamespaceReleases, setHelmReleases] = useState<
    HelmNamespaceReleases[]
  >([])
  const [environmentsStatus, setEnvironmentsStatus] = useState<
    Array<EnvironmentStatusSpec>
  >([])

  const [loadingHelmReleases, setLoadingHelmReleases] = useState<boolean>(false)
  const [failedHelmReleases, setFailedHelmReleases] = useState<boolean>(false)
  const [errorMessageHelmReleases, setErrorMessageHelmReleases] =
    useState<string>('')

  const getHelmReleases = async () => {
    try {
      setLoadingHelmReleases(true)
      setErrorMessageHelmReleases('')
      setFailedHelmReleases(false)

      const data: HelmNamespaceReleases[] =
        await apiClient.getServiceHelmReleasesNamespaces(uuid || '')
      setHelmReleases(data)
    } catch (e) {
      console.error(e)
      setFailedHelmReleases(true)
      if (e instanceof Error) {
        setErrorMessageHelmReleases(e.message)
      } else {
        setErrorMessageHelmReleases('unknown')
      }
    } finally {
      setLoadingHelmReleases(false)
    }
  }

  const getServiceNamespaces = async () => {
    try {
      setLoadingServiceNamespaces(true)
      setErrorMessageServiceNamespaces('')
      setFailedServiceNamespaces(false)

      const data: KubernetesNamespaceSpec[] =
        await apiClient.getServiceNamespaces(uuid || '')
      setServiceNamespaces(data)
    } catch (e) {
      console.log(e)
      setFailedServiceNamespaces(true)
      if (e instanceof Error) {
        setErrorMessageServiceNamespaces(e.message)
      } else {
        setErrorMessageServiceNamespaces('unknown')
      }
    } finally {
      setLoadingServiceNamespaces(false)
    }
  }

  useEffect(() => {
    getHelmReleases()
    getServiceNamespaces()
  }, [])

  useEffect(() => {
    const getEnvironmentsStatus = async () => {
      const tmpEnvironmentsStatus: EnvironmentStatusSpec[] = []
      const tmpEnvironmentsCount: Map<string, StatusCountsSpec> = new Map()
      const tmpEnvironmentNamespaces: Map<string, string[]> = new Map()
      const environments: Array<string> = []

      if (serviceNamespaces) {
        for (const namespace of serviceNamespaces) {
          // Получаем список окружений
          if (
            namespace.environmentRef?.name &&
            !environments.includes(namespace.environmentRef?.name)
          ) {
            environments.push(namespace.environmentRef.name)
          }

          // Проходим по списку helmRelease для данного namespace
          let countWarning: number = 0
          let countError: number = 0
          let countSuccess: number = 0
          let countPending: number = 0

          for (const item of helmNamespaceReleases) {
            if (item.namespace === namespace.name) {
              // Определяем последнюю версию релиза и его статус
              const lastVersionReleases: Map<string, number> = new Map()
              const lastStatusReleases: Map<string, string> = new Map()

              for (const release of item.releases) {
                if (release.name) {
                  let currentLastVersionRelease: number = 0

                  const tmpLastVersionRelease = lastVersionReleases.get(
                    release.name
                  )

                  if (tmpLastVersionRelease !== undefined) {
                    currentLastVersionRelease = tmpLastVersionRelease
                  }

                  if (
                    release.version &&
                    currentLastVersionRelease < release.version
                  ) {
                    lastVersionReleases.set(release.name, release.version)

                    if (release.info?.status) {
                      lastStatusReleases.set(release.name, release.info?.status)
                    }
                  }
                }
              }

              // Считаем count
              Array.from(lastStatusReleases).map((value) => {
                if (
                  value[1] === HelmReleaseStatus.Deployed ||
                  value[1] === HelmReleaseStatus.Uninstalled ||
                  value[1] === HelmReleaseStatus.Superseded
                ) {
                  countSuccess += 1
                }

                if (value[1] === HelmReleaseStatus.Failed) {
                  countError += 1
                }

                if (
                  value[1] === HelmReleaseStatus.Uninstalling ||
                  value[1] === HelmReleaseStatus.PendingInstall ||
                  value[1] === HelmReleaseStatus.PendingUpgrade ||
                  value[1] === HelmReleaseStatus.PendingRollback
                ) {
                  countPending += 1
                }

                if (value[1] === HelmReleaseStatus.Unknow) {
                  countWarning += 1
                }
              })
            }
          }

          if (namespace.environmentRef?.name) {
            // Добавляем окружение в список
            let environmentNamespaces = tmpEnvironmentNamespaces.get(
              namespace.environmentRef?.name
            )

            if (environmentNamespaces === undefined) {
              environmentNamespaces = [namespace.name]
            } else {
              environmentNamespaces.push(namespace.name)
            }

            tmpEnvironmentNamespaces.set(
              namespace.environmentRef.name,
              environmentNamespaces
            )

            // Сохраняем count
            let environmentCounts = tmpEnvironmentsCount.get(
              namespace.environmentRef?.name
            )

            if (environmentCounts === undefined) {
              environmentCounts = {
                warnings: countWarning,
                pendings: countPending,
                errors: countError,
                success: countSuccess,
              }
            } else {
              environmentCounts.warnings += countWarning
              environmentCounts.pendings += countPending
              environmentCounts.errors += countError
              environmentCounts.success += countSuccess
            }

            tmpEnvironmentsCount.set(
              namespace.environmentRef.name,
              environmentCounts
            )
          }
        }
      }

      // Формируем статус окружения
      for (const environment of environments) {
        // Получаем данные
        const environmentCounts = tmpEnvironmentsCount.get(environment)
        const environmentNamespaces = tmpEnvironmentNamespaces.get(environment)

        if (
          environmentCounts !== undefined &&
          environmentNamespaces !== undefined
        ) {
          // Высчитываем статус
          let status = EnvironmentStatus.NotFound

          if (
            environmentCounts.success > 0 &&
            environmentCounts.errors === 0 &&
            environmentCounts.warnings === 0 &&
            environmentCounts.pendings === 0
          ) {
            status = EnvironmentStatus.Success
          }

          if (
            environmentCounts.success === 0 &&
            environmentCounts.errors > 0 &&
            environmentCounts.warnings === 0 &&
            environmentCounts.pendings === 0
          ) {
            status = EnvironmentStatus.Failed
          }

          if (
            environmentCounts.success > 0 &&
            (environmentCounts.errors > 0 || environmentCounts.warnings > 0) &&
            environmentCounts.pendings >= 0
          ) {
            status = EnvironmentStatus.Warning
          }

          if (
            environmentCounts.success >= 0 &&
            environmentCounts.errors === 0 &&
            environmentCounts.warnings === 0 &&
            environmentCounts.pendings > 0
          ) {
            status = EnvironmentStatus.Pending
          }

          // Фиксируем
          const tmp: EnvironmentStatusSpec = {
            name: environment,
            namespaces: environmentNamespaces,
            status: status,
          }

          tmpEnvironmentsStatus.push(tmp)
        }
      }

      setEnvironmentsStatus(tmpEnvironmentsStatus)
    }

    if (service && helmNamespaceReleases) {
      getEnvironmentsStatus()
    }
  }, [service, helmNamespaceReleases])

  return (
    <Card style={{ minHeight: '250px', boxShadow: '1' }}>
      <CustomCardHeader
        height="80px"
        title="Статус окружений"
        description="Статус деплоя на окружения"
        fullPage={false}
      />

      {(loadingHelmReleases || loadingServiceNamespaces) && <LinearProgress />}
      {!loadingHelmReleases &&
        !loadingServiceNamespaces &&
        (failedHelmReleases || failedServiceNamespaces) && (
          <>
            {errorMessageHelmReleases !== '' && (
              <Alert severity="error">{errorMessageHelmReleases}</Alert>
            )}
            {errorMessageServiceNamespaces !== '' && (
              <Alert severity="error">{errorMessageServiceNamespaces}</Alert>
            )}
          </>
        )}
      {!loadingHelmReleases &&
        !loadingServiceNamespaces &&
        (!failedHelmReleases || !failedServiceNamespaces) && (
          <CardContent>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                      Окружение
                    </TableCell>
                    <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                      Namespaces
                    </TableCell>
                    <TableCell align="left" sx={{ fontWeight: 'bold' }}>
                      Статус
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.from(environmentsStatus.entries()).map((value) => {
                    return <EnvironmentStatusRow environmentStatus={value[1]} />
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        )}
    </Card>
  )
}

import { InfraServiceSpec } from '@edp/types'
import { ApiClient } from 'api/ApiClient'
import {
  CustomSelectComponent,
  CustomSelectProps,
} from 'components/CustomSelectComponent/CustomSelectComponent'
import { useEffect, useState } from 'react'

export interface InfraServiceSelectProps extends CustomSelectProps {
  type: string
  initId?: number
  setId?: (id: number) => void
}

export const InfraServiceSelect = (props: InfraServiceSelectProps) => {
  const apiClient = new ApiClient()
  const [options, setOptions] = useState<Array<string>>([])
  const [infraServicesSpec, setInfraServicesSpec] = useState<
    InfraServiceSpec[]
  >([])

  useEffect(() => {
    const getInfraServices = async () => {
      if (props.type) {
        try {
          const data = await apiClient.getInfraServicesByType(props.type)
          const infraServices: string[] = []

          setInfraServicesSpec(data)

          for (const infraService of data) {
            infraServices.push(infraService.name)
          }

          setOptions(infraServices)

          if (props.initId) {
            for (const infraService of data) {
              if (String(infraService.id) === String(props.initId)) {
                props.setSelected(infraService.name)
                continue
              }
            }
          }
        } catch (e) {
          console.error('Ошибка при получении данных:', e)
        }
      }
    }

    getInfraServices()
  }, [props.type])

  useEffect(() => {
    if (props.setId !== undefined) {
      for (const infraService of infraServicesSpec) {
        if (infraService.name === props.selected) {
          const id = Number(infraService.id)
          props.setId(id)
        }
      }
    }
  }, [props.selected])

  return <CustomSelectComponent options={options} {...props} />
}

export default InfraServiceSelect

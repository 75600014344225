import { GeneralCustomVariableSpec } from '@edp/types'
import { observer } from 'mobx-react-lite'
import SettingsStore from './store'
import { CircularProgress, Grid, Typography } from '@mui/material'
import { UserInteractionAddButton } from 'components/UserInteraction/UserInteractionAddButton'
import UserInteractionTwoTextFields from 'components/UserInteraction/UserInteractionTwoTextFields'
import { runInAction } from 'mobx'

export interface CustomVariablesProps {
  loadingCustomVariables: boolean
}

export interface CustomVariableItemProps {
  index: number
  name: string
  value: string
}

const CustomVariableItem = observer((props: CustomVariableItemProps) => {
  const handleChangeName = (index: number, value: string) => {
    const validate = (value: string) => {
      if (value === 'EDP_') {
        return true
      }

      const pattern = /^EDP_[A-Za-z0-9_]+$/
      return pattern.test(value)
    }

    runInAction(() => {
      if (!validate(value)) {
        return
      }

      const customVariables: GeneralCustomVariableSpec[] =
        SettingsStore.$('customVariables').value

      customVariables[index].name = value
      SettingsStore.$('customVariables').set(customVariables)
    })
  }

  const handleChangeValue = (index: number, value: string) => {
    runInAction(() => {
      const customVariables: GeneralCustomVariableSpec[] =
        SettingsStore.$('customVariables').value

      customVariables[index].value = value
      SettingsStore.$('customVariables').set(customVariables)
    })
  }

  const handleRemoveCustomVariable = (index: number) => {
    runInAction(() => {
      const customVariables: GeneralCustomVariableSpec[] =
        SettingsStore.$('customVariables').value

      customVariables.splice(index, 1)

      SettingsStore.$('customVariables').reset()
      SettingsStore.$('customVariables').set(customVariables)
    })
  }

  return (
    <UserInteractionTwoTextFields
      key={`custom-variable-${props.index}`}
      helperText="Пользовательская переменная, заданная на уровне EDP"
      index={props.index}
      first={props.name}
      firstLabel="Название"
      second={props.value}
      secondLabel="Значение"
      handleChangeFirst={(e) => {
        handleChangeName(props.index, e.target.value)
      }}
      handleChangeSecond={(e) => {
        handleChangeValue(props.index, e.target.value)
      }}
      removeButton
      handleRemove={() => {
        handleRemoveCustomVariable(props.index)
      }}
    />
  )
})

const CustomVariables = observer((props: CustomVariablesProps) => {
  const handleAddCustomVariable = () => {
    const customVariables: GeneralCustomVariableSpec[] =
      SettingsStore.$('customVariables').value

    if (customVariables.length !== 0) {
      console.log(customVariables[customVariables.length - 1].name)
      if (
        (customVariables[customVariables.length - 1].name !== '' &&
          customVariables[customVariables.length - 1].name !== 'EDP_') ||
        customVariables[customVariables.length - 1].value !== ''
      ) {
        const config: GeneralCustomVariableSpec[] =
          SettingsStore.$('customVariables').value
        config.push({
          name: 'EDP_',
          value: '',
        })
        SettingsStore.$('customVariables').reset()
        SettingsStore.$('customVariables').set(config)
      }
    } else {
      const config: GeneralCustomVariableSpec[] =
        SettingsStore.$('customVariables').value
      config.push({
        name: 'EDP_',
        value: '',
      })
      SettingsStore.$('customVariables').reset()
      SettingsStore.$('customVariables').set(config)
    }
  }

  return (
    <>
      <Typography
        variant="body1"
        paddingTop="30px"
        className="Typography-Body1"
      >
        Пользовательские переменные
      </Typography>
      <Grid
        sx={{
          marginBottom: '10px',
        }}
      >
        {!props.loadingCustomVariables ? (
          <>
            {SettingsStore.$('customVariables').value.length > 0 && (
              <>
                {SettingsStore.$('customVariables').value.map(
                  (
                    customVariable: GeneralCustomVariableSpec,
                    index: number
                  ) => {
                    return (
                      <CustomVariableItem
                        index={index}
                        name={customVariable.name}
                        value={customVariable.value}
                      />
                    )
                  }
                )}
              </>
            )}
            <div
              style={{
                marginLeft: '5px',
              }}
            >
              <UserInteractionAddButton handleAdd={handleAddCustomVariable} />
            </div>
          </>
        ) : (
          <>
            <CircularProgress size={20} />
          </>
        )}
      </Grid>
    </>
  )
})

export default CustomVariables

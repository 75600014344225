import {
  Cancel,
  CheckCircle,
} from '@edp/core-common-frontend/dist/packages/mui/icons-material'
import {
  PrometheusQueryResultVectorTypeSpec,
  ServicePrometheusMetricResultMetricSpec,
  ServiceSpec,
} from '@edp/types'
import { CircularProgress, Tooltip } from '@mui/material'
import { ApiClient } from 'api/ApiClient'
import { ReactNode, useEffect, useState } from 'react'

export interface HealtcheckStatusComponentProps {
  service: ServiceSpec
  fontSize: string
}

export const HealthcheckStatusComponent = (
  props: HealtcheckStatusComponentProps
) => {
  const [status, setStatus] = useState<string>()
  const [value, setValue] = useState<number>()
  const [metric, setMetric] =
    useState<ServicePrometheusMetricResultMetricSpec>()
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const getHealtcheckMetric = async () => {
      try {
        setLoading(true)

        if (props.service.uuid) {
          const apiClient = new ApiClient()
          const result = await apiClient.getServicePrometheusMetricsHealthcheck(
            props.service.uuid
          )

          if (result.metrics.length > 0) {
            setMetric(result.metrics[0])

            // Получаем значение
            const tmpValue = result.metrics[0].metric.data
              .result as Array<PrometheusQueryResultVectorTypeSpec>
            const invalidData: number = -1
            let value: number = invalidData

            // Преобразуем
            if (tmpValue.length > 0 && tmpValue[0].value !== undefined) {
              if (String(tmpValue[0].value[1]) === 'NaN') {
                value = 0
              } else {
                value = Number(Number(tmpValue[0].value[1]).toFixed(2))
              }
            }

            // Устанавливаем значение
            setValue(value)

            // Определяем статус
            if (value === invalidData) {
              setStatus('unknown')
            } else if (value < Number(result.metrics[0].threshold)) {
              setStatus('success')
            } else {
              setStatus('error')
            }
          } else {
            setStatus('unknown')
          }
        } else {
          setStatus('unknown')
        }
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false)
      }
    }

    getHealtcheckMetric()
  }, [props.service])

  const getElement = (): ReactNode => {
    if (metric !== undefined) {
      const msg = `${metric.name}: ${String(value)} ${
        metric.unit
      }\nПороговое значение: ${String(metric.threshold)}`

      return (
        <>
          {status === 'success' && (
            <Tooltip
              title={msg}
              placement="bottom"
              classes={{
                popper: 'tooltipPopperServiceClass',
                tooltipPlacementBottom: 'tooltipPopperServiceClassInfo',
              }}
            >
              <CheckCircle
                style={{
                  fontSize: `${props.fontSize}`,
                  color: '#4caf50',
                }}
              />
            </Tooltip>
          )}
          {status === 'error' && (
            <Tooltip
              title={msg}
              placement="bottom"
              classes={{
                popper: 'tooltipPopperServiceClass',
                tooltipPlacementBottom: 'tooltipPopperServiceClassInfo',
              }}
            >
              <Cancel
                style={{
                  fontSize: `${props.fontSize}`,
                  color: '#f44336',
                }}
              />
            </Tooltip>
          )}
        </>
      )
    }
  }

  return <>{!loading ? getElement() : <CircularProgress size={20} />}</>
}

export default HealthcheckStatusComponent
